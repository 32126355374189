<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>

    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="true"
    >
    <el-table-column prop="name" label="设备名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.product_name}}
            
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="task_ruku_sn" label="施工号" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.task_ruku_sn}}
            
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="model" label="规格型号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.model}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="volume" label="容量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.volume}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="enter_factory_time" label="进厂时间" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.enter_factory_time}}
          </div>
        </template>
      </el-table-column>
    <el-table-column prop="ruku_time" label="入库时间" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.ruku_time}}
          </div>
        </template>
      </el-table-column>
    <el-table-column prop="songkuang_time" label="送矿时间" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.songkuang_time}}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="附件" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="left">
        <template #header>
          <el-image
          v-for="item in form.appendix"
          style="width: 100px; height: 100px"
          :src="item"
          :key="item"
          :preview-src-list="[item]"
        ></el-image>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<style>
.el-table__empty-block{
  display: none !important;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      fileList: [],
      rowCount: 1,
      status:0,
      form: {
        id:'',
        appendix:"",
        detail:[{
          name: "",
            task_ruku_sn: "",
            model: "",
            volume:"",
            enter_factory_time:"",
            ruku_time:"",
            songkuang_time:""
        }]
      },
      crumb: ["入库单", "详情"],
    };
  },
  mounted() {
    let param = this.$route.params;
      operate.editTaskruku(param, "get").then((response) => {
        if (response.code == "200") {
          let info = response.data;
          this.form = info[0].taskRukuInfo;
          this.form.detail = info;
        }
      });

  },
  methods: {
  
    onSubmit() {

    },
  },
};
</script>