<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/manager/add"
      :showButton="false"
    ></Breadcrumb>
    <el-button slot="append" class="searchbtn" @click="showAssign"
      >分配人员</el-button
    >

    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="昵称"> </el-table-column>
      <el-table-column prop="role" label="工作组"> </el-table-column>
      <el-table-column prop="workerorder" label="工单"> </el-table-column>
      <el-table-column prop="workerorder_sn" label="工单号"> </el-table-column>
      <el-table-column prop="process" label="步骤"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tableData)"
            type="text"
            size="small"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog title="分配工单步骤" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="工单：" :label-width="formLabelWidth">
          <el-input
            v-model="workerorderInfo.name"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="分给：" :label-width="formLabelWidth">
          <el-select multiple v-model="to_manager_id" placeholder="请选择">
            <el-option
              v-for="item in teamMember"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="步骤：" :label-width="formLabelWidth">
          <el-checkbox-group v-model="checkProcessLavel">
            <el-checkbox
              :label="item.process_name"
              v-for="(item, index) in process"
              :key="index"
            ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="assign">分配</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      dialogTableVisible: false,
      dialogFormVisible: false,
      loading: false,
      keyword: "",
      total: 0,
      form: {},
      tableData: [],
      teamMember: [],
      process: [],
      checkProcessLavel: [],
      checkProcessId:[],
      to_manager_id: '',
      workerorderInfo: [],
      workerorder_id:0,
      crumb: ["人员分配"],
    };
  },
  methods: {
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      operate.deleteManagerWorkerorder({ id: _info.id }).then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
    showAssign() {
      this.dialogFormVisible = true;
    },
    assign() {
      let that=this
      that.checkProcessLavel.forEach(function(item){
        that.process.forEach(function(_item){
          if(item==_item.process_name){
            that.checkProcessId.push(_item.process_id)
          }
        })
      })
      operate
        .addManagerWorkerorder({
          workerorder_id: that.workerorder_id,
          process_id: that.checkProcessId,
          to_manager_id: that.to_manager_id,
        })
        .then((res) => {
          window.location.reload();
        });
    },
    searchput() {},
    getKeyword() {},
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/edit/" + id;
      this.$router.push(path);
    },
    getList(p) {},
  },
  mounted() {
    let that = this;
    let param = this.$route.params;
    console.log(param);
    if (param && param["workerorder_id"]) {
      that.workerorder_id = param["workerorder_id"];
    }
    operate
      .getAllManagerWorkerorder({ workerorder_id: that.workerorder_id })
      .then((response) => {
        that.tableData = response.data;
        that.total = response.count;
      });
    operate.getTeamMember({}).then((response) => {
      if (response.code == "200") {
        let info = response.data;
        that.teamMember = info;
      }
    });
    operate
      .getWorkerOrderProcess({ workerorder_id: that.workerorder_id })
      .then((response) => {
        if (response.code == "200") {
          let info = response.data;
          that.process = info;
        }
      });
    if (that.workerorder_id) {
      operate
        .editWorkerOrder({ id: that.workerorder_id }, "get")
        .then((response) => {
          if (response.code == "200") {
            let info = response.data;
            that.workerorderInfo = info;
            that.crumb.unshift("工单：" + info.name);
          }
        });
    }
  },
};
</script>