<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column
        label="备注"
        show-overflow-tooltip
        min-width="16.99%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input v-model="form.remarks" type="textarea" :rows="4" />
        </template>
      </el-table-column>
    </el-table>

    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="true"
    >
      <el-table-column
        label="设备名称"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.product_name"
              style="width: 100%"
             disabled
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="施工号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.task_paichan_sn"
              style="width: 100%"
             disabled
             v-if="scope.row.status==1" 
            ></el-input>
            <el-input
              size="small"
              v-model="scope.row.task_paichan_sn"
              style="width: 100%"
              v-else
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.num"
              style="width: 100%"
              v-if="scope.row.status==1" disabled
            ></el-input>
            <el-input
              size="small"
              v-model="scope.row.num"
              style="width: 100%"
              v-else
            ></el-input>
          </div>
        </template>
      </el-table-column>


      <el-table-column align="left"  label="车间">
        <template slot-scope="scope">
          <el-select v-model="scope.row.workshop_id" placeholder="选择工单">
            <el-option
              v-for="item in workshop"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>


      <!-- <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="onDelLayer(scope.$index, scope.row)"
            v-if="scope.row.status==0"
            >[-]</el-button>
          <el-button
            type="text"
            v-if="scope.row.status==1"
            >已排产</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div style="margin: 10px 0px">
      <el-upload
        class="upload-demo"
        action="/api/Upload/uploadImage"
        :on-preview="uploadPreview"
        :on-remove="uploadRemove"
        :on-success="uploadCallBack"
        multiple
        :file-list="fileList"
        list-type="picture-card"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <div style="text-align: center">
      <el-button type="primary" @click="onSubmit">排产</el-button>
    </div>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      rowCount: 1,
      fileList: [],
      workshop:[],
      workshop_id:'',
      form: {
        remarks: "",
        appendix: "",
        detail: [
          {
            product_id:0,
            sn:'',
            task_paichan_sn:'',
            num:'',
            workshop_id:''
          }
        ],
      },
      crumb: ["任务通知单", "排产"],
    };
  },
  mounted() {
    let param = this.$route.params;
    let that = this;
    operate.editTasknotification(param, "get").then((response) => {
      if (response.code == "200") {
          let info=response.data;
          that.form.detail[0].sn=info[0].sn;
          that.form.detail[0].product_id=info[0].product_id;
          that.form.detail[0].product_name=info[0].product_name;
      }
    });
    operate.getAllWorkshop().then((response) => {
      this.workshop = response.data;
    });
  },
  methods: {
    uploadRemove(file, fileList) {
      let _fileList = [];
      this.fileList.forEach((item) => {
        if (item.name != file.name) {
          _fileList.push(item);
        }
      });
      this.fileList = _fileList;
    },
    uploadPreview(file) {},
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response._path,
          path: response.path,
          url:  response.path,
        };
        this.fileList.push(_obj);
      }
    },
    onAddLayer() {
      var that = this;
      if (that.form.detail) {
        that.form.detail.push({
          product_id: "",
          name: "",
          task_paichan_sn: "",
          num: "",
          product: that.product,
        });
      } else {
        that.form.detail = [
          {
            product_id: "",
            name: "",
            task_paichan_sn: "",
            num: "",
            product: that.product,
          },
        ];
      }
      this.rowCount = that.form.detail.length;
    },
    onDelLayer(index, row) {
      this.form.detail.splice(index, 1);
      this.rowCount = this.detail.length;
    },
    onSubmit() {
      if (this.fileList.length > 0) {
        for (let k in this.fileList) {
          this.form.appendix += this.fileList[k].name + ";";
        }
      }
      operate.addTaskpaichan(this.form, "post").then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
  },
};
</script>