<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/manager/add"
      :showButton="true"
    ></Breadcrumb>
    <el-form :inline="true">
      <el-form-item label="身份">
        <el-cascader
            :options="roles"
            :props="{ checkStrictly: true }"
            @change="roleChange"
            :show-all-levels="false"
            clearable>
      </el-cascader>
      </el-form-item>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
          @change="getKeyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="昵称"> </el-table-column>
      <el-table-column prop="role_info.name" label="身份"> </el-table-column>
      <el-table-column prop="add_time" label="添加时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index, tableData)"
            type="text"
            size="small"
          >
            移除
          </el-button>
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
          <el-button
            :data-index="scope.$index"
            @click.native.prevent="getQrcode(scope.$index)"
            type="text"
            size="small"
          >
            绑定公众号
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="qrCodeTitle"
      height="400px"
      width="400px"
    >
      <el-image style="width: 100%; height: auto" :src="qrcode"></el-image>
    </el-dialog>
  </div>
</template>
  
  <script>
  import operate from "../../api/api";
import { getAllManager, deletetManaer, mpCode } from "../../api/user";
export default {
  data() {
    return {
      qrCodeTitle: "扫描二维码",
      loading: false,
      dialogVisible: false,
      keyword: "",
      roles:[],
      role_id:"",
      total: 0,
      tableData: [],
      qrcode: "",
      crumb: ["管理员", "列表"],
    };
  },
  methods: {
    deleteRow(index, rows) {
      let _info = this.tableData[index];
      this.$confirm("确定要删除用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          deletetManaer({ id: _info.id }).then((response) => {
            if (response.code == "200") {
              this.$message({
                message: response.msg,
                type: "success",
                onClose: function () {
                  window.location.reload();
                },
              });
            }
          });
        })
        .catch(() => {});
    },
    searchput() {
      getAllManager({ name: this.keyword,role_id:this.role_id }).then((response) => {
        this.tableData = response.data;
        this.total = response.count;
      });
    },
    getKeyword() {},
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/edit/" + id;
      this.$router.push(path);
    },
    getQrcode(index) {
      let _info = this.tableData[index];
      mpCode({
        id: _info.id,
      }).then((response) => {
        if (response.code == "200") {
          let _data = response.data;
          this.qrcode = _data.qrcode;
          this.qrCodeTitle = _data.name;
          this.dialogVisible = true;
        }
      });
    },
    getList(p) {
      getAllManager({ page: p, }).then((response) => {
        this.tableData = response.data;
        this.total = response.count;
      });
    },
    roleChange(value){
      this.role_id=value[value.length-1];
    },
  },
  mounted() {
    operate.getRoleTree({roleLevel:3}).then((response)=>{
      let _role = [
        { value: 0, label: "管理员"}
      ];
      this.roles=_role.concat(response.data);
     })
    getAllManager().then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
  },
};
</script>