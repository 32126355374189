<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="上传头像">
        <el-upload
          class="avatar-uploader"
          action="/api/Upload/uploadImage"
          :show-file-list="false"
          :on-remove="uploadRemove"
          :on-success="uploadCallBack"
        >
          <img v-if="ruleForm.face" :src="ruleForm.face" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import { getAuth, editManager } from "../../api/user";
import operate from "../../api/api.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
        checkPass: "",
        id: 0,
        face: ""
      },
      rules: {
      },
      _face: "",
    };
  },
  methods: {
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response.path,
          path: response.path,
          url: response.path,
        };
        this._face = response._path;
        this.ruleForm.face = response.path;
      }
    },
    uploadRemove(file, fileList) {
      this.ruleForm.face = "";
    },
    submitForm() {
      let _data = {
        id: this.ruleForm.id,
        face: this._face,
      };
      if (this.ruleForm.password && this.ruleForm.checkPass) {
        _data.password = this.ruleForm.password;
        _data.repass = this.ruleForm.checkPass;
      }
      editManager(_data, "post").then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
  },
  mounted() {
    let param = this.$route.params;
    let id = param["id"];
    editManager({ id: id }, "get").then((response) => {
      let that=this
      that.ruleForm = response.data;
    });
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader-icon {
  line-height: 178px !important;
}
</style>