<template>
   <el-container>
      <el-aside width="padding: 0 20px;">
        <common-aside></common-aside>
      </el-aside>
      <el-container>
        <el-header>
          <common-header></common-header>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
</template>
<script>
import CommonAside from "../../components/CommonAside.vue"
import CommonHeader from "../../components/CommonHeader.vue";
export default {
  name: "App",
  components: {CommonAside,CommonHeader},
};
</script>
<style scoped>
.el-header{
  padding:0;
  height:auto;
}
.el-main{
  padding:0
}
</style>

