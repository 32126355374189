<template>
  <div class="right-content">
    <Breadcrumb addpath="/task/add" :crumb="crumb" :showButton="false"></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
  import operate from '../../api/api.js'
export default {
  data() {
    return {
      form: {
        name: ""
      },
      workerorder:[],
      crumb:['任务工单','添加']
    };
  },
  methods: {
   
    onSubmit() {
      let that=this
      operate.addTask(this.form).then((response)=>{
        if(response.code=='200'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
              that.$router.back()
            }
        })
        }
     })
    },
  },
  mounted() {
    operate.getAllWorkerOrder().then((response)=>{
      this.workerorder=response.data
    });
  },
};
</script>