<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="false"
    >
      <el-table-column
        prop="name"
        label="产品名称"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.product_name"
              style="width: 100%"
              disabled
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="client" label="委托单位" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.client"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="task_shengchan_sn" label="计划号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.task_shengchan_sn"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column> -->

      <el-table-column prop="unit" label="单位" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.unit"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="num" label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.num"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180" align="center" v-if="handle">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="onDelLayer(scope.$index, scope.row)"
            v-if="scope.$index"
            >[-]</el-button
          >
          <el-button
            type="text"
            @click="onAddLayer"
            v-if="scope.$index === rowCount - 1"
            >[+]</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column
      label="班组"
      show-overflow-tooltip
      min-width="20%"
      align="center"
      >
      </el-table-column>

      <el-table-column align="left">
      <template #header>
      <el-cascader
      :options="roles"
      :props="{ checkStrictly: true }"
      @change="roleChange"
      :show-all-levels="false"
      clearable> </el-cascader>
      </template>
      </el-table-column>

      <el-table-column
        label="工单名称"
        show-overflow-tooltip
        min-width="20%"
        align="center"
      >
      </el-table-column>

      <el-table-column align="left">
        <template #header>
          <div class="input-box">
            <el-input
              size="small"
              v-model="form.workerOrder.name"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="工单模板"
        show-overflow-tooltip
        min-width="22%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="left">
        <template #header>
          <el-select v-model="form.workerOrder.template_id" placeholder="选择工单">
            <el-option
              v-for="item in template"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>


      <el-table-column
        label="预估时间"
        show-overflow-tooltip
        min-width="22%"
        align="center"
      >
      </el-table-column>

      <el-table-column align="left">
        <template #header>
          <el-date-picker
          v-model="form.workerOrder.eta_time"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
        </template>
      </el-table-column>



    </el-table>

    <div style="margin: 10px 0px">
      <el-upload
        class="upload-demo"
        action="/api/Upload/uploadImage"
        :on-preview="uploadPreview"
        :on-remove="uploadRemove"
        :on-success="uploadCallBack"
        multiple
        :file-list="fileList"
        list-type="picture-card"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <div style="text-align: center">
      <el-button type="primary" @click="onSubmit">开始生产</el-button>
    </div>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      rowCount: 1,
      handle: false,
      fileList: [],
      template: [],
      info: [],
      roles: [],
      form: {
        appendix: "",
        sn:"",
        role_id:'',
        detail: [
          {
            sn: "",
            product_id: 0,
            product_name: "",
          
            task_shengchan_sn: "",
            num: "",
            client: "",
            unit: "",
          },
        ],
        workerOrder: {
          task_sn:"",
          template_id: "",
          product_id: 0,
          name: "",
          eta_time: "",
        },
      },
      crumb: ["生产单", "填写"],
    };
  },
  mounted() {
    let param = this.$route.params;
    let that = this;
    operate.editTaskpaichan(param, "get").then((response) => {
      if (response.code == "200") {
        let info = response.data;
        that.form.detail = info;
        that.form.workerOrder.task_sn=info[0].sn;
        that.form.workerOrder.product_id=info[0].product_id;
        that.form.sn = info[0].sn;
      }
    });
    operate.getAllTemplate().then((response) => {
      this.template = response.data;
    });
    operate.getRoleTree({roleLevel:3}).then((response)=>{
      let _role = [
        { value: 0, label: "管理员"}
      ];
      this.roles=_role.concat(response.data);
     })
  },
  methods: {
    roleChange(value){
      this.form.role_id=value[value.length-1];
    },
    uploadRemove(file, fileList) {
      let _fileList = [];
      console.log("remove file");
      console.log(file);
      this.fileList.forEach((item) => {
        if (item.name != file.name) {
          _fileList.push(item);
        }
      });
      this.fileList = _fileList;
    },
    uploadPreview(file) {
      console.log(file);
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response._path,
          path: response.path,
          url:response.path,
        };
        this.fileList.push(_obj);
      }
    },
    onAddLayer() {
      var that = this;
      if (that.form.detail) {
        that.form.detail.push({
          name: "",
          task_paichan_sn: "",
          num: "",
        });
      } else {
        that.form.detail = [
          {
            name: "",
            task_paichan_sn: "",
            num: "",
          },
        ];
      }
      this.rowCount = that.form.detail.length;
    },
    onDelLayer(index, row) {
      this.form.detail.splice(index, 1);
      this.rowCount = this.detail.length;
    },
    onSubmit() {
      if (this.fileList.length > 0) {
        for (let k in this.fileList) {
          this.form.appendix += this.fileList[k].name + ";";
        }
      }
      operate.addTaskshengchan(this.form, "post").then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
  },
};
</script>