<template>
  <div class="right-content">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="计划内容" prop="resource">
        <el-input
          type="textarea"
          autosize
          :disabled="!userInfo.auth.includes('editJihua')"
          v-model="form.jihua_remarks"
        ></el-input>
      </el-form-item>
    
     
      <el-form-item
        label="车间审核"
        prop="resource"
        v-if="status==1&&userInfo.auth.includes('verifyJihua')"
      >
      <el-radio-group v-model="form.jihua_status">
            <el-radio :label="1">待审核</el-radio>
            <el-radio :label="2">审核通过</el-radio>
            <el-radio :label="-1">审核拒绝</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item
        label="部室审核"
        prop="resource"
        v-if="status==2&&status_2==0&&userInfo.auth.includes('verifyJihua2')"
      >
      <el-radio-group v-model="form.jihua_status_2">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="-1">审核拒绝</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item
        label="公司审核"
        prop="resource"
        v-if="status==2&&status_2==1&&status_3==0&&userInfo.auth.includes('verifyJihua3')"
      >
      <el-radio-group v-model="form.jihua_status_3">
            <el-radio :label="0">待审核</el-radio>
            <el-radio :label="1">审核通过</el-radio>
            <el-radio :label="-1">审核拒绝</el-radio>
      </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="text-align: center" v-if="userInfo.auth.includes('editJihua')||
    (form.jihua_status==1&&userInfo.auth.includes('verifyJihua'))||
    (form.jihua_status==2&&form.jihua_status_2==0&&userInfo.auth.includes('verifyJihua2'))||
    (form.jihua_status==2&&form.jihua_status_2==1&&form.jihua_status_3==0&&userInfo.auth.includes('verifyJihua3'))
    ">
      <el-button
        type="primary"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import operate from "../../api/api.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      userInfo:{},
      status:0,
      status_2:0,
      status_3:0,
      form: {
        jihua_remarks: "",
        jihua_status: 0,
        jihua_status_2:0,
        jihua_status_3:0
      },
    };
  },
  props: ["info"],
  created() {
    this.getInfo();
  },
  watch: {
    info(newval, oldval) {
      this.info = newval;
      this.getInfo();
    },
  },
  filters: {},
  mounted() {
    let userInfo=localStorage.getItem('userInfo')
    this.userInfo=JSON.parse(userInfo)
    console.log(this.userInfo)
  },
  methods: {
    getInfo() {
      let _data = this.info;
      this.status=this.info.jihua_status
      this.status_2=this.info.jihua_status_2
      this.status_3=this.info.jihua_status_3
      this.form= _data;
    },
   onSubmit() {
      if (this.form.jihua_remarks=='') {
          this.$message({
            message: "请填写计划内容",
            type: "warning",
          });
          return;
      }
      if(this.userInfo.auth.includes('verifyJihua')){
        if(!this.status&&this.info.jihua_status==0){
          this.form.jihua_status=1;
        }
      }else if(this.userInfo.auth.includes('editJihua')){
        this.form.jihua_status=1;//修改后，状态变为待审核
        this.form.jihua_status_2=0;
        this.form.jihua_status_3=0;
      }
      operate
        .chaijian(
          {
            id: this.info.id,
            jihua_remarks: this.form.jihua_remarks,
            jihua_status: this.form.jihua_status,
            jihua_status_2: this.form.jihua_status_2,
            jihua_status_3: this.form.jihua_status_3,
          },
          "post"
        )
        .then((response) => {
          if (response.code == "200") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/*去掉表格单元格边框*/
.customer-no-border-table th {
  border: none;
}
.customer-no-border-table td,
.customer-no-border-table th.is-leaf {
  border: none;
}
/*表格最外边框*/
.customer-no-border-table .el-table--border,
.el-table--group {
  border: none;
}
/*头部边框*/
.customer-no-border-table thead tr th.is-leaf {
  border: 0px solid #ebeef5;
  border-right: none;
}
.customer-no-border-table thead tr th:nth-last-of-type(2) {
  border-right: 0px solid #ebeef5;
}
/*表格最外层边框-底部边框*/
.customer-no-border-table .el-table--border::after,
.customer-no-border-table .el-table--group::after {
  width: 0;
}
.customer-no-border-table::before {
  width: 0;
}
.customer-no-border-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
.customer-no-border-table .el-table__header tr th {
  background: #fff;
  color: #333333;
  padding: 3px;
  fontweight: 550;
  height: 36px;
  border: 0px;
  font-size: 15px;
}
</style>