<template>
  <div class="header-content">
    <div class="l-content">
      <i :class="[isLeft?leftClass:rightClass]" @click="handleAside"></i>
    </div>
    <div class="r-content">
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link" style="display:flex;align-items: center;">
          <div style="font-size: 16px;font-weight: bold;margin-right: 5px;">{{ userInfo.name }}</div>
          <img :src="userImg" class="user" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="edit">账号编辑</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import operate from "../api/api.js";
export default {
  components: {},
  // 定义属性
  data() {
    return {
      userImg: require("../assets/user.png"),
      isLeft:true,
      leftClass:'el-icon-caret-left',
      rightClass:'el-icon-caret-right',
      userInfo:{}
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleAside(){
      this.isLeft=!this.isLeft
        this.$store.commit('collapsemenu')
    },
    handleCommand(command){
      if(command=='logout'){
        localStorage.removeItem('userInfo');
        window.location.href="/"
      }else if(command=='edit'){
        let path = "/manager/editinfo/" + this.userInfo.id;
        this.$router.push(path);
      }
       
    },
  },
  mounted() {
    let userInfo=localStorage.getItem('userInfo')
    this.userInfo=JSON.parse(userInfo)
    console.log(this.userInfo)
    this.userImg=this.userInfo.face
  }
};
</script>

<style lang='scss' scoped>
.header-content {
  height: 60px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
  padding-right: 20px;
}
.l-content {
  display: flex;
  align-items: center;
  i {
    margin: 0 20px;
    cursor: pointer;
  }
}
.r-content {
  .user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>