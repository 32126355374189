<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/TaskNotification/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="title" label="销售单标题"> </el-table-column>
      <el-table-column prop="client" label="委托单位"> </el-table-column>
      <el-table-column prop="product_name" label="产品"> </el-table-column>
      <el-table-column prop="add_manager" label="上传人"> </el-table-column>
      <el-table-column prop="add_time" label="上传时间"> </el-table-column>
      <el-table-column prop="urgent_text" label="紧急状态"> </el-table-column>
      <el-table-column prop="status_text" label="状态"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="chaijian(scope.$index)"
            type="text"
            size="small"
            v-if="scope.row.category_id==2"
          >
          <div v-html="$options.filters.chaijianAction(scope.row.chaijian_status)"></div>
          </el-button>
          <el-button
            @click.native.prevent="jihua(scope.$index)"
            type="text"
            size="small"
            v-if="scope.row.category_id==2"
          >
          <div v-html="$options.filters.jihuaAction(scope.row.jihua_status)"></div>
          </el-button>
         
          <el-button
            @click.native.prevent="add(scope.$index)"
            type="text"
            size="small"
            v-if="
              (scope.row.category_id==2&&
              scope.row.task_notification_status != 0 &&
              scope.row.status == 0 &&
              scope.row.chaijian_status==2&&
              scope.row.jihua_status==2&&
              userInfo.auth.includes('handleNotification'))||
              (scope.row.category_id==1&&
              scope.row.task_notification_status != 0 &&
              scope.row.status == 0 &&
              userInfo.auth.includes('handleNotification'))
            "
          >
            <div style="color:#67C23A">安排排产</div>
          </el-button>
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            通知单详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog
      title="拆检信息"
      :visible.sync="chaijianVisible"
      width="80%"
    >
      <chaijian :info="info">
      </chaijian>
    </el-dialog>
    <el-dialog
      title="计划信息"
      :visible.sync="jihuaVisible"
      width="80%"
    >
      <jihua :info="info">
      </jihua>
    </el-dialog>
  </div>
</template>
<script>
import operate from "../../api/api";
import chaijian from './chaijian.vue';
import jihua from './jihua.vue';
export default {
  data() {
    return {
      userInfo: {},
      loading: false,
      chaijianVisible:false,
      jihuaVisible:false,
      chaijianType:'add',
      jihuaType:'add',
      id:0,
      keyword: "",
      total: 0,
      tableData: [],
      info:'',
      crumb: ["任务通知单", "列表"],
    };
  },
  components:{chaijian,jihua},
  filters: {
    chaijianAction(value){
        if (value == 0) {
          return '<span style="color:#6D76EF">提交拆验</span>';
        } else if (value == 1) {
          return '<span style="color:#E6A23C">拆验审核</span>';
        }  else if (value == 2) {
          return '<span style="color:#409EFF">拆验详情</span>';
        }
        else if (value == -1) {
          return '<span style="color:#409EFF">修改拆验</span>';
        }
    },
    jihuaAction(value){
        if (value == 0) {
          return '<span style="color:#6D76EF">提交计划</span>';
        } else if (value == 1) {
          return '<span style="color:#E6A23C">计划审核</spa>';
        }  else if (value == 2) {
          return '<span style="color:#409EFF">计划详情</span>';
        }
        else if (value == -1) {
          return '<span style="color:#409EFF">修改计划</span>';
        }
    }
  },
  methods: {
    searchput(e) {
      operate
        .getAllTasknotification({ name: this.keyword })
        .then((response) => {
          this.tableData = response.data;
          this.total = response.count;
        });
    },
    edit(index) {
      let id = this.tableData[index].id;
      let path = "/tasknotification/edit/" + id;
      this.$router.push(path);
    },
    add(index) {
      let id = this.tableData[index].id;
      let path = "/tasknotification/add/" + id;
      this.$router.push(path);
    },
    getList(p) {
      operate.getAllTasknotification({ page: p }).then((response) => {
        this.tableData = response.data;
        this.total = response.count;
      });
    },
    chaijian(index){
      let info = this.tableData[index];
      this.info=info
      this.chaijianVisible=true;
    },
    jihua(index){
      let info = this.tableData[index];
      this.info=info
      this.jihuaVisible=true;
    },
  },
  mounted() {
    let userInfo = localStorage.getItem("userInfo");
    this.userInfo = JSON.parse(userInfo);
    operate.getAllTasknotification().then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
  },
};
</script>