<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
    <el-form-item label="上传头像">
        <el-upload
          class="avatar-uploader"
          action="/api/Upload/uploadImage"
          :show-file-list="false"
          :on-success="uploadCallBack"
          >
          <i class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      </el-form-item>
      <el-form-item label="昵称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="2-15个字或者字母"></el-input>
      </el-form-item>
      <el-form-item label="企业">
        <el-select v-model="ruleForm.company_id" placeholder="请选择">
          <el-option
            v-for="item in company"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="身份">
        <el-cascader
            :options="roles"
            :props="{ checkStrictly: true }"
            @change="roleChange"
            :show-all-levels="false"
            clearable>
      </el-cascader>
      </el-form-item>
      <el-form-item label="小组负责人" v-if="ruleForm.role_id">
        <el-select v-model="ruleForm.is_leader" placeholder="请选择">
          <el-option
            v-for="item in leaderOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责车间">
        <el-select v-model="ruleForm.workshop_id" placeholder="请选择">
          <el-option
            v-for="item in workshopOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="权限">
        <el-tree
          :data="auth"
          show-checkbox
          node-key="name"
          ref="auth"
          @check-change="handleNodeClick"
          :props="defaultProps"
          :default-expand-all="false"
        >
        </el-tree>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >立即创建</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import { getAuth, addManager } from "../../api/user";
import operate from "../../api/api.js";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        name: "",
        password: "",
        checkPass: "",
        role_id: 0,
        company_id:'',
        is_leader: "",
        auth:"",
        face:""
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
        name: [
          { required: true, message: "昵称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      leaderOption: [
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ],
      roles: [],
      workshopOption:[],
      company:[],
      auth: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      _face:""
    };
  },
  methods: {
    handleNodeClick(node){
      var parentIds = this.$refs.auth.getHalfCheckedKeys()
      var childsIds = this.$refs.auth.getCheckedKeys()
      console.log(parentIds)
      console.log(childsIds)
      this.ruleForm.auth=parentIds.concat(childsIds)
  },
    roleChange(value){
      this.ruleForm.role_id=value[value.length-1];
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response.path,
          path: response.path,
          url: response.path,
        };
        this._face=response._path;
        this.ruleForm.face=response.path;
      }
    },
    submitForm(formName) {
      this.ruleForm.face=this._face;
      addManager(this.ruleForm).then((res) => {
        if (res.code == "200") {
          this.$message({
            message: "添加成功",
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
    getCheckedAuth() {
      const _auth = this.$refs.auth.getCheckedKeys();
      if(_auth.length>0){
        this.ruleForm.auth=_auth;
      }
    },
  },
  mounted() {
    getAuth().then((response) => {
      this.auth = response.data;
    });
    operate.getRoleTree({roleLevel:3}).then((response)=>{
      let _role = [
        { value: 0, label: "管理员"}
      ];
      this.roles=_role.concat(response.data);
     })
    operate.getAllCompany({ is_all: true }).then((res) => {
      this.company =res.data;
    });
    operate.getAllWorkshop({ is_all: true }).then((res) => {
      this.workshopOption =res.data;
    });
  },
};
</script>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar-uploader-icon{
    line-height: 178px !important;
  }
</style>