<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/workerorder/add"
      :crumb="crumb"
      :showButton="true"
    ></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="标题">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="产品">
        <el-select v-model="form.product_id" placeholder="产品">
          <el-option
            v-for="item in allProduct"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产出量">
        <el-input v-model="form.output"></el-input>
      </el-form-item>
      <el-form-item label="结束时间">
        <el-date-picker
          v-model="form.end_time"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="模板">
        <el-select
          v-model="template_id"
          disabled
          placeholder="请选择"
          @change="changeType"
        >
          <el-option
            v-for="item in template"
            value-format="yyyy-M-d"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      form: {
        name: "",
        id: 0,
        end_time: "",
        product_id:"",
        output: 1,
      },
      teamMember: [],
      template_id: 0,
      template: [],
      allProduct: [],
      userInfo: "",
      crumb: ["工单", "编辑"],
    };
  },
  mounted() {
    let param = this.$route.params;
    let that = this;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    operate.editWorkerOrder(param, "get").then((response) => {
      if (response.code == "200") {
        let info = response.data;
        that.form.id = info.id;
        that.form.name = info.name;
        that.form.output = info.output;
        that.form.end_time = info.end_time;
        that.form.state = info.state;
        that.form.product_id=info.product_id;
        that.template_id = info.template_id;
        that.crumb.push(info.name);
      }
    });
    operate.getAllTemplate(param).then((response) => {
      if (response.code == "200") {
        let info = response.data;
        that.template = info;
      }
    });
    operate.getAllProduct({'is_all':true}).then((response)=>{
      that.allProduct=response.data
    });
  },
  methods: {
    onSubmit() {
      let that = this;
      operate.editWorkerOrder(that.form, "post").then((response) => {
        if (response.code == "200") {
          that.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              that.$router.back();
            },
          });
        }
      });
    },
    changeType(e) {},
  },
};
</script>