<template>
  <div class="right-content">
    <Breadcrumb addpath="/role/add" :crumb="crumb" :showButton="false"></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="上级角色">
        <el-cascader
            :options="allRoleTree"
            :props="{ checkStrictly: true }"
            :show-all-levels="false"
            @change="roleChange"
            clearable>
      </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
  import operate from '../../api/api.js'
export default {
  data() {
    return {
      
      form: {
        name: "",
        unit:'',
        pid:''
      },
      allRoleTree:[],
      crumb:['工作组','添加']
    };
  },
  mounted(){
    let that=this
    operate.getRoleTree({}).then((response)=>{
        if(response.code=='200'){
         that.allRoleTree=response.data
        }
     })
  },
  methods: {
    roleChange(value){
      console.log(value);
      this.form.pid=value[value.length-1];
      console.log(this.form)
    },
    onSubmit() {
      operate.addRole(this.form).then((response)=>{
        if(response.code=='200'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
  },
};
</script>