<template>
    <div class="right-content">
      <Breadcrumb
        addpath="/role/add"
        :crumb="crumb"
        :showButton="false"
      ></Breadcrumb>
      <el-table
        ref="historyTable"
        :data="form.detail"
        style="width: 100%"
        size="small"
        :border="false"
      >
        <el-table-column
          prop="name"
          label="产品名称"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <div class="input-box">
              <el-input
                size="small"
                v-model="scope.row.product_name"
                style="width: 100%"
                disabled
              ></el-input>
            </div>
          </template>
        </el-table-column>  
        <el-table-column prop="task_shengchan_sn" label="设备编号" align="center">
          <template slot-scope="scope">
            <div class="input-box">
              <el-input
                size="small"
                v-model="scope.row.product_sn"
                style="width: 100%"
              ></el-input>
            </div>
          </template>
        </el-table-column>
  
        <el-table-column prop="model" label="规格型号" align="center">
          <template slot-scope="scope">
            <div class="input-box">
              <el-input
                size="small"
                v-model="scope.row.model"
                style="width: 100%"
              ></el-input>
            </div>
          </template>
        </el-table-column>
  
        <el-table-column prop="num" label="数量" align="center">
          <template slot-scope="scope">
            <div class="input-box">
              <el-input
                size="small"
                v-model="scope.row.num"
                style="width: 100%"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remaks" label="备注" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.remaks"
              style="width: 100%"
              type="textarea"
            ></el-input>
          </div>
        </template>
      </el-table-column>
        <el-table-column label="操作" width="180" align="center" v-if="handle">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="onDelLayer(scope.$index, scope.row)"
              v-if="scope.$index"
              >[-]</el-button
            >
            <el-button
              type="text"
              @click="onAddLayer"
              v-if="scope.$index === rowCount - 1"
              >[+]</el-button
            >
          </template>
        </el-table-column>
      </el-table>
  
      <div style="margin: 10px 0px">
        <el-upload
          class="upload-demo"
          action="/api/Upload/uploadImage"
          :on-preview="uploadPreview"
          :on-remove="uploadRemove"
          :on-success="uploadCallBack"
          multiple
          :file-list="fileList"
          list-type="picture-card"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div style="text-align: center">
        <el-button type="primary" @click="onSubmit">开始销售</el-button>
      </div>
    </div>
  </template>
<script>
  import operate from "../../api/api.js";
  export default {
    data() {
      return {
        formLabelWidth: "120px",
        handle: false,
        fileList: [],
        form: {
          appendix: "",
          sn: "",
          detail: [
            {
              sn: "",
              product_id: 0,
              product_name: "",
              product_sn: "",
              num: "",
              model: "",
              remaks:""
            },
          ]
        },
        crumb: ["销售单", "填写"],
      };
    },
    mounted() {
      let param = this.$route.params;
      let that = this;
      operate.editTaskruku(param, "get").then((response) => {
        if (response.code == "200") {
          let info = response.data;
          that.form.sn = info[0].sn;
          that.form.detail[0].sn = info[0].sn;
          that.form.detail[0].product_id = info[0].product_id;
          that.form.detail[0].product_name = info[0].product_name;
        }
      });
    },
    methods: {
      uploadRemove(file, fileList) {
        let _fileList = [];
        console.log("remove file");
        console.log(file);
        this.fileList.forEach((item) => {
          if (item.name != file.name) {
            _fileList.push(item);
          }
        });
        this.fileList = _fileList;
      },
      uploadPreview(file) {
        console.log(file);
      },
      uploadCallBack(response, file, fileList) {
        if (response.code == 200) {
          let _obj = {
            name: response._path,
            path: response.path,
            url:  response.path,
          };
          this.fileList.push(_obj);
        }
      },
      onAddLayer() {
        var that = this;
        if (that.form.detail) {
          that.form.detail.push({
            name: "",
            task_paichan_sn: "",
            num: "",
          });
        } else {
          that.form.detail = [
            {
              name: "",
              task_paichan_sn: "",
              num: "",
            },
          ];
        }
        this.rowCount = that.form.detail.length;
      },
      onDelLayer(index, row) {
        this.form.detail.splice(index, 1);
        this.rowCount = this.detail.length;
      },
      onSubmit() {
        if (this.fileList.length > 0) {
          for (let k in this.fileList) {
            this.form.appendix += this.fileList[k].name + ";";
          }
        }
        operate.addTaskxiaoshou(this.form, "post").then((response) => {
          if (response.code == "200") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
      },
    },
  };
  </script>