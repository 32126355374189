<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="备注" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div style="font-weight:normal">
            {{ form.paichanInfo.remarks }}
          </div>
        </template>
      </el-table-column>
    </el-table>


    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="true"
    >
      <el-table-column label="设备名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.product_name}}
            
          </div>
        </template>
      </el-table-column>
      <el-table-column label="施工号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.task_paichan_sn}}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.num}}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="附件" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="left">
        <template #header>
          <el-image
          v-for="item in form.paichanInfo.appendix"
          style="width: 100px; height: 100px"
          :src="item"
          :key="item"
          :preview-src-list="[item]"
        ></el-image>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<style>
.el-table__empty-block{
  display: none !important;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      fileList: [],
      rowCount: 1,
      status:0,
      form: {},
      crumb: ["排产单", "详情"],
    };
  },
  mounted() {
    let param = this.$route.params;
      operate.editTaskpaichan(param, "get").then((response) => {
        if (response.code == "200") {
          let info = response.data;
          this.form = info[0];
          this.form.detail=info;
        }
      });

  },
  methods: {
  
    onSubmit() {

    },
  },
};
</script>