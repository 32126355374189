<template>
  <div class="right-content">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="说明" prop="resource">
        <el-input
          type="textarea"
          autosize
          :disabled="!userInfo.auth.includes('editChaijian')"
          v-model="form.chaijian_remarks"
        ></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="resource">
        <div style="margin: 10px 0px">
          <el-upload
            class="upload-demo"
            action="/api/Upload/uploadImage"
            :on-preview="uploadPreview"
            :on-remove="uploadRemove"
            :on-success="uploadCallBack"
            multiple
            :file-list="form.fileList"
            list-type="picture-card"
            v-if="userInfo.auth.includes('editChaijian')"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <template v-else>
            <el-image
              v-for="item in form.c_chaijian_appendix"
              style="width: 100px; height: 100px"
              :src="item"
              :key="item"
              :preview-src-list="[item]"
            ></el-image>
          </template>
        </div>
      </el-form-item>
      <el-form-item
        label="状态"
        prop="resource"
        v-if="status==1&&userInfo.auth.includes('verifyChaijian')"
      >
      <el-radio-group v-model="form.chaijian_status">
            <el-radio :label="1">待审核</el-radio>
            <el-radio :label="2">审核通过</el-radio>
            <el-radio :label="-1">审核拒绝</el-radio>
      </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="text-align: center" v-if="userInfo.auth.includes('editChaijian')||(form.chaijian_status==1&&userInfo.auth.includes('verifyChaijian'))">
      <el-button
        type="primary"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import operate from "../../api/api.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      userInfo:{},
      status:0,
      form: {
        chaijian_remarks: "",
        chaijian_appendix: "",
        c_chaijian_appendix: "",
        chaijian_status: 0,
        fileList: []
      },
    };
  },
  props: ["info"],
  created() {
    this.getInfo();
  },
  watch: {
    info(newval, oldval) {
      this.info = newval;
      this.getInfo();
    },
  },
  filters: {},
  mounted() {
    let userInfo=localStorage.getItem('userInfo')
    this.userInfo=JSON.parse(userInfo)
    console.log(this.userInfo)
  },
  methods: {
    getInfo() {
      let _data = this.info;
      this.form.c_chaijian_appendix = _data._chaijian_appendix;
      this.form.chaijian_appendix = _data.chaijian_appendix;
      this.form.chaijian_remarks = _data.chaijian_remarks;
      this.form.chaijian_status = _data.chaijian_status;
      this.status=_data.chaijian_status;
      this.form.fileList=[];
      if (_data.chaijian_appendix.length > 0) {
        let imageArr = _data.chaijian_appendix.split(";");
        for (let _k in imageArr) {
          if (imageArr[_k].length > 0) {
            let _obj = {
              name: imageArr[_k],
              path: _data._chaijian_appendix[_k],
              url: _data._chaijian_appendix[_k],
            };
            this.form.fileList.push(_obj);
          }
        }
      }
    },
    uploadRemove(file, fileList) {
      let _fileList = [];
      this.form.fileList.forEach((item) => {
        if (item.name != file.name) {
          _fileList.push(item);
        }
      });
      this.form.fileList = _fileList;
    },
    uploadPreview(file) {},
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response._path,
          path: response.path,
          url: response.path,
        };
        this.form.fileList.push(_obj);
      }
    },
    onAddLayer() {},
    onDelLayer(index, row) {},
    onSubmit() {
      if (this.form.fileList.length > 0) {
        this.form.chaijian_appendix = "";
        for (let k in this.form.fileList) {
          this.form.chaijian_appendix += this.form.fileList[k].name + ";";
        }
      } else {
        if (this.form.chaijian_appendix.length <= 0) {
          this.$message({
            message: "请上传附件",
            type: "warning",
          });
          return;
        }
      }
      if(this.userInfo.auth.includes('verifyChaijian')){
        if(!this.status&&this.info.chaijian_status==0){
          this.form.chaijian_status=1;
        }
      }else if(this.userInfo.auth.includes('editChaijian')){
        this.form.chaijian_status=1;//修改后，状态变为待审核
      }
      operate
        .chaijian(
          {
            id: this.info.id,
            chaijian_remarks: this.form.chaijian_remarks,
            chaijian_appendix: this.form.chaijian_appendix,
            chaijian_status:this.form.chaijian_status,
          },
          "post"
        )
        .then((response) => {
          if (response.code == "200") {
            this.$message({
              message: response.msg,
              type: "success",
              onClose: function () {
                window.location.reload();
              },
            });
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
/*去掉表格单元格边框*/
.customer-no-border-table th {
  border: none;
}
.customer-no-border-table td,
.customer-no-border-table th.is-leaf {
  border: none;
}
/*表格最外边框*/
.customer-no-border-table .el-table--border,
.el-table--group {
  border: none;
}
/*头部边框*/
.customer-no-border-table thead tr th.is-leaf {
  border: 0px solid #ebeef5;
  border-right: none;
}
.customer-no-border-table thead tr th:nth-last-of-type(2) {
  border-right: 0px solid #ebeef5;
}
/*表格最外层边框-底部边框*/
.customer-no-border-table .el-table--border::after,
.customer-no-border-table .el-table--group::after {
  width: 0;
}
.customer-no-border-table::before {
  width: 0;
}
.customer-no-border-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
.customer-no-border-table .el-table__header tr th {
  background: #fff;
  color: #333333;
  padding: 3px;
  fontweight: 550;
  height: 36px;
  border: 0px;
  font-size: 15px;
}
</style>