<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>

    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="true"
    >
      <el-table-column prop="name" label="设备名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.product_name}}
            
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="product_sn" label="设备编号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.product_sn}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="model" label="型号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.model}}
          </div>
        </template>
      </el-table-column>


      <el-table-column prop="num" label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.num}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="remaks" label="备注" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.remaks"
              style="width: 100%"
              type="textarea"
              :disabled="true"
            ></el-input>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="附件" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="left">
        <template #header>
          <el-image
          v-for="item in form.appendix"
          style="width: 100px; height: 100px"
          :src="item"
          :key="item"
          :preview-src-list="[item]"
        ></el-image>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<style>
.el-table__empty-block{
  display: none !important;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      fileList: [],
      rowCount: 1,
      status:0,
      form: {
        appendix:"",
        detail:[{
          product_name: "",
          product_sn:"",
          model: "",
          num: ""
        }]
      },
      crumb: ["销售单", "详情"],
    };
  },
  mounted() {
    let param = this.$route.params;
      operate.editTaskxiaoshou(param, "get").then((response) => {
        if (response.code == "200") {
          let info = response.data;
          this.form = info[0].taskXiaoshouInfo;
          this.form.detail=info;
        }
      });

  }
};
</script>