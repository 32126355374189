<template>
    <el-dialog
            title="数据信息"
            :visible.sync="dialogVisible"
            width="70%"
    >
        <el-alert
                title="使用说明"
                type="warning"
                description="以下流程信息可以被存储起来，方便下一次流程加载"
                show-icon
                close-text="知道了"
        >
        </el-alert>
        <br/>
        <!--一个高亮显示的插件-->
        <codemirror
                :value="flowJsonData"
                :options="options"
                class="code"
        ></codemirror>
    </el-dialog>
</template>

<script>
    import { Message } from "element-ui"
   import operate from '../../api/api.js'
    import 'codemirror/lib/codemirror.css'
    import { codemirror } from 'vue-codemirror'

    require("codemirror/mode/javascript/javascript.js")

    export default {
        props: {
            data: Object,
        },
        data() {
            return {
                dialogVisible: false,
                flowJsonData: {},
                options: {
                    mode: {name: "javascript", json: true},
                    lineNumbers: true
                }
            }
        },
        components: {
            codemirror
        },
        methods: {
            init(id=0) {
                // this.dialogVisible = true
                this.flowJsonData = JSON.stringify(this.data, null, 4).toString()
            
                let param={
                    'name':this.data.name,
                    'nodeList':this.data.nodeList,
                    'lineList':this.data.lineList
                }
                if(id){
                    param.id=id;
                    operate.editTemplate(param,'post').then((response)=>{
                        Message({
                                    type: 'success',
                                    message: response.msg||"成功",
                                    duration:1000,
                                    showClose:true
                        });

                    })
                    return;
                }
                operate.addTemplate(param).then((response)=>{
                    Message({
                                type: 'success',
                                message: response.msg||"成功",
                                duration:1000,
                                showClose:true
                     });
                })
            }
        }
    }
</script>
