<template>
  <div class="right-content">
    <Breadcrumb addpath="/company/add" :crumb="crumb" :showButton="false"></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="标题">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="公告内容">
        <el-input type="textarea" v-model="form.content"></el-input>
      </el-form-item>
      <el-form-item v-if="userInfo.auth.includes('addNotice')">
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
  import operate from '../../api/api.js'
export default {
  data() {
    return {
      userInfo:{},
      form: {
        name: "",
        content:'',
        id:0
      },
      crumb:['公告','编辑']
    };
  },
  mounted(){
    let param=this.$route.params
    let that=this
    let userInfo=localStorage.getItem('userInfo')
    this.userInfo=JSON.parse(userInfo)
    operate.editNotice(param,'get').then((response)=>{
        if(response.code=='200'){
          let info=response.data
          console.log(info)
          that.form=info
        }
     })
  },
  methods: {
    onSubmit() {
      operate.editNotice(this.form,'post').then((response)=>{
        if(response.code=='200'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
  },
};
</script>