<template>
  <div v-if="easyFlowVisible" style="height: 500px">
    <el-row>
      <!--顶部工具菜单-->
      <el-col :span="24">
        <div
          class="ef-tooltar"
          style="
            display: flex;
            margin-right: 5px;
            height: 50px;
            line-height: 50px;
          "
        >
          <div style="width: 50%; margin-right: 10px">
            <label for="name" class="el-form-item__label"
              >工单名称：{{ name }}</label
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <div style="display: flex; height: calc(100% - 47px)">
      <div id="efContainer" ref="efContainer" class="container" v-flowDrag>
        <template v-for="node in data.nodeList">
          <flow-node
            :id="node.id"
            :key="node.id"
            :node="node"
            :activeElement="activeElement"
            @changeNodeSite="changeNodeSite"
            @nodeRightMenu="nodeRightMenu"
            @clickNode="clickNode"
          >
          </flow-node>
        </template>
        <!-- 给画布一个默认的宽度和高度 -->
        <!-- <div style="position:absolute;top: 2000px;left: 2000px;">&nbsp;</div> -->
        <div style="position: absolute">&nbsp;</div>
      </div>
      <!-- 右侧表单 -->
    </div>
    <!-- 流程数据详情 -->
    <flow-info v-if="flowInfoVisible" ref="flowInfo" :data="data"></flow-info>
    <flow-help v-if="flowHelpVisible" ref="flowHelp"></flow-help>
    <el-dialog :title="clickNodeName" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="form.mask"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传附件" :label-width="formLabelWidth">
          <el-upload
            class="upload-demo"
            action="/api/Upload/uploadImage"
            :on-preview="uploadPreview"
            :on-remove="uploadRemove"
            :on-success="uploadCallBack"
            multiple
            :file-list="fileList"
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="guaqi">挂起</el-button>
        <el-button type="primary" @click="wancheng">完成</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="步骤审核信息"
      :visible.sync="actionhistoryVisible"
      width="80%"
    >
      <actionhistory :workorderId="workerorder_id" :processId="process_id">
      </actionhistory>
    </el-dialog>
  </div>
</template>
<script>
import operate from "../../api/api";
import { jsPlumb } from "@/components/ef/jsplumb";
import { easyFlowMixin } from "@/components/ef/mixins";
import flowNode from "@/components/ef/node";
import nodeMenu from "@/components/ef/node_menu";
import FlowInfo from "@/components/ef/info";
import FlowHelp from "@/components/ef/help";
import actionhistory from "./actionhistory.vue";
import lodash from "lodash";

export default {
  data() {
    return {
      userInfo: "",
      workerorder_id: 0,
      process_id: 0,
      actionhistoryVisible: false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      fileList: [],
      form: {
        mask: "",
      },
      managerWorkerorder: [],
      clickNodeName: "",
      formLabelWidth: "120px",
      // jsPlumb 实例
      jsPlumb: null,
      // 控制画布销毁
      easyFlowVisible: true,
      // 控制流程数据显示与隐藏
      flowInfoVisible: false,
      // 是否加载完毕标志位
      loadEasyFlowFinish: false,
      flowHelpVisible: false,
      // 数据
      data: {},
      name: "",
      // 激活的元素、可能是节点、可能是连线
      activeElement: {
        // 可选值 node 、line
        type: undefined,
        // 节点ID
        nodeId: undefined,
        // 连线ID
        sourceId: undefined,
        targetId: undefined,
      },
      zoom: 0.5,
      clickNodeId: 0, //记录双击nodeID
    };
  },
  // 一些基础配置移动该文件中
  mixins: [easyFlowMixin],
  components: {
    flowNode,
    nodeMenu,
    FlowInfo,
    FlowHelp,
    actionhistory,
  },
  directives: {
    flowDrag: {
      bind(el, binding, vnode, oldNode) {
        if (!binding) {
          return;
        }
        el.onmousedown = (e) => {
          if (e.button == 2) {
            // 右键不管
            return;
          }
          //  鼠标按下，计算当前原始距离可视区的高度
          let disX = e.clientX;
          let disY = e.clientY;
          el.style.cursor = "move";

          document.onmousemove = function (e) {
            // 移动时禁止默认事件
            e.preventDefault();
            const left = e.clientX - disX;
            disX = e.clientX;
            el.scrollLeft += -left;

            const top = e.clientY - disY;
            disY = e.clientY;
            el.scrollTop += -top;
          };

          document.onmouseup = function (e) {
            el.style.cursor = "auto";
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  mounted() {
    let param = this.$route.params;
    this.template_id = param["template_id"];
    this.workerorder_id = param["workerorder_id"];
    this.jsPlumb = jsPlumb.getInstance();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.$nextTick(() => {
      operate
        .editTemplate({
          template_id: this.template_id,
          workerorder_id: this.workerorder_id,
        })
        .then((response) => {
          if (response.code == "200") {
            this.dataReload(response.info);
          }
        });
      operate.editWorkerOrder({ id: this.workerorder_id }).then((response) => {
        if (response.code == "200") {
          this.name = response.data.name;
        }
      });
      operate
        .getManagerWorkerOrder({ workerorder_id: this.workerorder_id })
        .then((response) => {
          if (response.code == "200") {
            this.managerWorkerorder = response.data;
          }
        });
    });
  },
  methods: {
    // 返回唯一标识
    getUUID() {
      return Math.random().toString(36).substr(3, 10);
    },
    jsPlumbInit() {
      this.jsPlumb.ready(() => {
        // 导入默认配置
        this.jsPlumb.importDefaults(this.jsplumbSetting);
        // 会使整个jsPlumb立即重绘。
        this.jsPlumb.setSuspendDrawing(false, true);
        // 初始化节点
        this.loadEasyFlow();
        this.jsPlumb.setContainer(this.$refs.efContainer);
      });
    },
    // 加载流程图
    loadEasyFlow() {
      // 初始化节点
      for (var i = 0; i < this.data.nodeList.length; i++) {
        let node = this.data.nodeList[i];
        // 设置源点，可以拖出线连接其他节点
        this.jsPlumb.makeSource(
          node.id,
          lodash.merge(this.jsplumbSourceOptions, {})
        );
        // // 设置目标点，其他源点拖出的线可以连接该节点
        this.jsPlumb.makeTarget(node.id, this.jsplumbTargetOptions);
        if (!node.viewOnly) {
          this.jsPlumb.draggable(node.id, {
            containment: "parent",
            stop: function (el) {},
          });
        }
      }
      // 初始化连线
      for (let i = 0; i < this.data.lineList.length; i++) {
        let line = this.data.lineList[i];
        var connParam = {
          source: line.from,
          target: line.to,
          label: line.label ? line.label : "",
          connector: line.connector ? line.connector : "",
          anchors: line.anchors ? line.anchors : undefined,
          paintStyle: line.paintStyle ? line.paintStyle : undefined,
        };
        this.jsPlumb.connect(connParam, this.jsplumbConnectOptions);
      }
      this.$nextTick(function () {
        this.loadEasyFlowFinish = true;
      });
    },

    // 改变节点的位置
    changeNodeSite(data) {
      for (var i = 0; i < this.data.nodeList.length; i++) {
        let node = this.data.nodeList[i];
        if (node.id === data.nodeId) {
          node.left = data.left;
          node.top = data.top;
        }
      }
    },
    getNodeById(nodeId) {
      for (let i = 0; i < this.data.nodeList.length; i++) {
        if (this.data.nodeList[i].id == nodeId) {
          return this.data.nodeList[i];
        }
      }
      return "";
    },
    uploadRemove(file, fileList) {
      let _fileList = [];
      console.log("remove file");
      console.log(file);
      this.fileList.forEach((item) => {
        if (item.name != file.name) {
          _fileList.push(item);
        }
      });
      this.fileList = _fileList;
    },
    uploadPreview(file) {
      console.log(file);
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response._path,
          path: response.path,
          url: response.path,
        };
        this.fileList.push(_obj);
      }
    },
    /**
     * 获取上一节点
     */
    findPreNode(nodeId) {
      for (let key in this.data.lineList) {
        if (this.data.lineList[key].to == nodeId) {
          let preNodeId = this.data.lineList[key].from;
          for (let _key in this.data.nodeList) {
            if (this.data.nodeList[_key].id == preNodeId) {
              return this.data.nodeList[_key];
            }
          }
        }
      }
      return false;
    },
    /**
     * 双击节点
     * **/
    clickNode(nodeId) {
      this.process_id = nodeId;
      this.clickNodeId = nodeId;
      this.mask = "";
      this.fileList = [];
      let that = this;
      let _showConfirmButton = true;

      let node = this.getNodeById(nodeId);
      if (this.userInfo.role_id != 0) {
        let _canHandle = false;
        this.managerWorkerorder.forEach(function (item) {
          if (item.process_id == clickNodeId) {
            _canHandle = true;
          }
        });
        if (!_canHandle) {
          _showConfirmButton = false;
        }
      }
      let pevNode = this.findPreNode(nodeId);
      //上一步如果不是完成状态，则不能进行下一步
      if (pevNode && pevNode.state != "success") {
        _showConfirmButton = false;
      }
      if (node.state == "success") {
        _showConfirmButton = false;
      }

      this.$confirm("请选择下一步操作", "提示", {
        confirmButtonText: "处理步骤",
        cancelButtonText: "查看历史记录",
        showConfirmButton: _showConfirmButton,
        type: "info ",
        center: true,
      })
        .then(() => {
          that.nextAction(nodeId);
        })
        .catch(() => {
          that.showProcessActionHistory();
        });
    },
    //双击后，进一步操作
    nextAction(nodeId) {
      let node = this.getNodeById(nodeId);
      // if (this.userInfo.role_id != 0) {
      //   let _canHandle=false;
      //   this.managerWorkerorder.forEach(function(item){
      //     if(item.process_id==clickNodeId){
      //       _canHandle=true
      //     }
      //   })
      //   if(!_canHandle){
      //     return;
      //   }
      // }

      // let pevNode = this.findPreNode(nodeId);
      // //上一步如果不是完成状态，则不能进行下一步
      // if (pevNode && pevNode.state != "success") {
      //   console.log('上一步未完成')
      //   return;
      // }
      this.clickNodeName = node.name;
      for (let key in this.data.nodeList) {
        if (this.data.nodeList[key].id == nodeId) {
          if (this.data.nodeList[key].state == "error") {
            this.handleErrorNode(this.data.nodeList[key]);
            break;
          } else if (this.data.nodeList[key].state == "success") {
            //完成步骤无需操作
            break;
          } else if (this.data.nodeList[key].state == "warning") {
            this.$message({
              message: "审核中",
              type: "warning",
            });
            break;
          } else {
            //获取当前节点信息

            this.fileList = [];
            this.dialogFormVisible = true;
            break;
          }
        }
      }
    },
    //步骤历史审核记录
    showProcessActionHistory() {
      this.actionhistoryVisible = true;
    },
    wancheng() {
      let workerorder_id = this.workerorder_id;
      let process_id = this.clickNodeId;
      let mask = this.form.mask;
      let images = "";
      if (this.fileList.length > 0) {
        for (let k in this.fileList) {
          images += this.fileList[k].name + ";";
        }
      }
      operate
        .addWkprocessaction({
          workerorder_id: workerorder_id,
          process_id: process_id,
          images: images,
          mask: mask,
          state: "success",
        })
        .then((res) => {
          window.location.reload();
        });
    },
    guaqi() {
      let workerorder_id = this.workerorder_id;
      let process_id = this.clickNodeId;
      let mask = this.form.mask;
      let images = "";
      if (this.fileList.length > 0) {
        for (let k in this.fileList) {
          images += this.fileList[k].name + ";";
        }
      }
      operate
        .addWkprocessaction({
          workerorder_id: workerorder_id,
          process_id: process_id,
          images: images,
          mask: mask,
          state: "error",
        })
        .then((res) => {
          window.location.reload();
        });
    },
    /**
     * 双击挂起节点
     *
     */
    handleErrorNode(node) {
      let nodeId = node.id;
      let html = "";
      let that = this;
      if (node.images != "null") {
        for (let item in node.images) {
          html +=
            "<image style='width:100px;height:100px' src='" +
            node.images[item] +
            "'/>";
        }
      }
      this.$confirm(
        '<p style="font-size:16px;font-weight:bold;padding-bottom:10px">挂起原因</p>' +
          node.mask +
          "<br>" +
          html,
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: "步骤完成",
        }
      )
        .then(() => {
          that.dialogFormVisible = true;
        })
        .catch((action) => {
          console.log(action);
        });
    },

    nodeRightMenu(nodeId, evt) {
      this.menu.show = true;
      this.menu.curNodeId = nodeId;
      this.menu.left = evt.x + "px";
      this.menu.top = evt.y + "px";
    },
    // 加载流程图
    dataReload(data) {
      console.log(data);
      this.easyFlowVisible = false;
      this.data.nodeList = [];
      this.data.lineList = [];
      this.$nextTick(() => {
        data = lodash.cloneDeep(data);
        this.easyFlowVisible = true;
        this.data = data;
        this.$nextTick(() => {
          this.jsPlumb = jsPlumb.getInstance();
          this.$nextTick(() => {
            this.jsPlumbInit();
          });
        });
      });
    },
  },
};
</script>
