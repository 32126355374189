<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/ProcessCategory/add"
      :crumb="crumb"
      :showButton="true"
      v-if="userInfo.auth.includes('addProcessCategory')"
    ></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="deleteRow(scope.$index)"
            type="text"
            size="small"
            v-if="userInfo.auth.includes('delComany')"
          >
            移除
          </el-button>
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
<script>
import operate from "../../api/api";
export default {
  data() {
    return {
      userInfo:{},
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      crumb: ["工单步骤分类", "列表"],
    };
  },
  methods: {
    deleteRow(index) {
      let _info = this.tableData[index];
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          operate.deleteProcessCategory({ id: _info.id }).then((response) => {
            if (response.code == "200") {
              this.$message({
                message: response.msg,
                type: "success",
                onClose: function () {
                  window.location.reload();
                },
              });
            }
          });
        })
        .catch(() => {});
    },
    searchput(e) {
      operate.getAllProcessCategory({ name: this.keyword }).then((response) => {
        this.tableData = response.data;
        this.total = response.count;
      });
    },
    edit(index) {
      let id = this.tableData[index].id;
      let path = "/processcategory/edit/" + id;
      this.$router.push(path);
    },
    getList(p) {
      operate.getAllProcessCategory({ page: p }).then((response) => {
        this.tableData = response.data;
        this.total = response.count;
      });
    },
  },
  mounted() {
    let userInfo=localStorage.getItem('userInfo')
    this.userInfo=JSON.parse(userInfo)
    operate.getAllProcessCategory().then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
  },
};
</script>