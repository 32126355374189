<template>
  <div class="right-content">
    <Breadcrumb addpath="/product/add" :crumb="crumb" :showButton="true"></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
  import operate from '../../api/api.js'
export default {
  data() {
    return {
     
      form: {
        name: "",
        unit:''
      },
      crumb:['产品','添加']
    };
  },
  methods: {
   
    onSubmit() {
      operate.addProduct(this.form).then((response)=>{
        if(response.code=='200'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
  },
};
</script>