<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="标题" show-overflow-tooltip min-width="25%" align="center">
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div style="font-weight:normal">
            {{ form.title }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="委托单位" show-overflow-tooltip min-width="25%" align="center">
      </el-table-column>
      <el-table-column align="center" min-width="25%">
        <template #header>
          <div style="font-weight:normal">
            {{ form.client }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="到货日期" show-overflow-tooltip min-width="25%" align="center">
      </el-table-column>
      <el-table-column align="center" min-width="25%">
        <template #header>
          <div style="font-weight:normal">
            {{ form.arrival_time }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="用户要求" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div style="font-weight:normal">
            {{ form.remarks }}
          </div>
        </template>
      </el-table-column>
    </el-table>


    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="true"
    >
      <el-table-column prop="name" label="设备名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.product_info.name}}
            
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="model" label="型号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.model}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="power" label="功率" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.power}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="num" label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.num}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="output_num" label="实际产量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.output_num"
              style="width: 100%"
              :disabled="true"
            ></el-input>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="附件" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="left">
        <template #header>
          <el-image
          v-for="item in form.appendix"
          style="width: 100px; height: 100px"
          :src="item"
          :key="item"
        ></el-image>
        </template>
      </el-table-column>
    </el-table>
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="margin-top: 20px"
      v-if="status == 0"
    >
      <el-form-item label="是否下发">
        <el-radio-group v-model="form.status">
          <el-radio :label="0">不下发</el-radio>
          <el-radio :label="1">任务下发</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否紧急">
        <el-radio-group v-model="form.urgent">
        <el-radio :label="1">正常生产</el-radio>
        <el-radio :label="2">加急</el-radio>
        <el-radio :label="3">着急</el-radio>
      </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<style>
.el-table__empty-block{
  display: none !important;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      fileList: [],
      rowCount: 1,
      status:0,
      form: {
        id:'',
        title:"",
        client: "",
        arrival_time: "",
        remarks:"",
        appendix:"",
        status:0,
        urgent:1,
        detail:[{
          name: "",
          model: "",
          power: "",
          num: "",
        }]
      },
      crumb: ["销售单", "详情"],
    };
  },
  mounted() {
    let param = this.$route.params;
      operate.editTasksales(param, "get").then((response) => {
        if (response.code == "200") {
          let info = response.data;
          this.status=info.status;
          this.form = info;
        }
      });

  },
  methods: {
  
    onSubmit() {
      operate.editTasksales(this.form,'post').then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
  },
};
</script>