<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#fff"
    text-color="#444"
    active-text-color="#409eff"
  >
  <h3 v-if="!isCollapse">后台管理</h3>
    <el-menu-item @click="clickmenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
      <i :class="item.icon"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu v-for="itemhasChildren in hasChildren" :key="itemhasChildren.name" :index="itemhasChildren.name">
      <template slot="title">
        <i :class="itemhasChildren.icon"></i>
        <span slot="title">{{ itemhasChildren.label }}</span>
      </template>
      <el-menu-item-group v-for="itemchildren in itemhasChildren.children" :key="itemchildren.name" :index="itemchildren.name">
        <el-menu-item @click="clickmenu(itemchildren)" :index="itemchildren.path">{{ itemchildren.label }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>


<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu-vertical-demo{
  /* height: 100vh; */
  /* width: 200px; */
}
h3{
  padding: 20px;
    text-align: center;
}
.el-submenu .el-menu-item{
  margin-left: 0px;
  padding-left: 60px !important;
}
</style>

<script>
import operate from "../api/api.js";
export default {
  data() {
    return {
      menuData: [
        {
          path: "/bd",
          name: "home",
          label: "首页",
          icon: "el-icon-s-home"
        },
        {
          name: "basci",
          label: "基础信息",
          icon: "el-icon-s-data",
          children: [
          {
              path: "/company",
              name: "company",
              label: "公司"
            },
            {
              path: "/role",
              name: "role",
              label: "工作组"
            },
            {
              path: "/material",
              name: "ycl",
              label: "原材料"
            },
            {
              path: "/process",
              name: "bz",
              label: "工单步骤"
            },
            {
              path: "/template",
              name: "mb",
              label: "工单模板"
            }
          ]
        },
        {
          name: "taskmanager",
          label: "任务单管理",
          icon:"el-icon-s-order",
          children: [
            {
              path: "/task",
              name: "rw",
              label: "任务工单"
            }
          ]
        },
        {
          name: "ordermanager",
          label: "工单管理",
          icon:"el-icon-s-order",
          children: [
            {
              path: "/task",
              name: "rw",
              label: "任务工单"
            },
            {
              path: "/workerorder",
              name: "gdmg",
              label: "所有工单",
              icon: "el-icon-s-claim"
            }
            ,
            {
              path: "/wkprocessaction",
              name: "wkprocessaction",
              label: "工单审核",
              icon: "el-icon-s-claim"
            }
          ]
        },
        {
          label: "系统设置",
          name:"sysset",
          icon: "el-icon-s-tools",
          children: [
            {
              path: "/manager",
              name: "ss",
              label: "管理员",
              icon: "el-icon-s-custom"
            }
          ]
        }
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {

    },
    handleClose(key, keyPath) {

    },
    clickmenu(item){
      if(this.$route.path!==item.path){
        this.$router.push(item.path)
      }

    }
  },
  computed:{
    noChildren(){
      return this.menuData.filter(item=>!item.children)
    },
    hasChildren(){
      return this.menuData.filter(item=>item.children)
    },
    isCollapse(){
      return this.$store.state.tab.isCollapse
    }
  },
  mounted() {
    operate.getNavigation().then((response) => {
      this.menuData = response.data;
    });
  }
};
</script>
