import request from "@/utils/request";
const operate = {
    getNavigation: function (params) {
        return request({
            url: "Navigate/index",
            method: 'post',
            data: params,
            params: params
        })
    },

    getAllProduct: function (params) {
        return request({
            url: "Product/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addProduct: function (params) {
        return request({
            url: "Product/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteProduct: function (params) {
        return request({
            url: "Product/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editProduct: function (params, method) {
        return request({
            url: "Product/edit",
            method: method,
            data: params,
            params: params
        })
    },

    getAllMaterial: function (params) {
        return request({
            url: "Material/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addMaterial: function (params) {
        return request({
            url: "Material/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteMaterial: function (params) {
        return request({
            url: "Material/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editMaterial: function (params, method) {
        return request({
            url: "Material/edit",
            method: method,
            data: params,
            params: params
        })
    },

    getRoleTree: function (params) {
        return request({
            url: "Role/tree",
            method: 'post',
            data: params,
            params: params
        })
    },

    getAllRole: function (params) {
        return request({
            url: "Role/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addRole: function (params) {
        return request({
            url: "Role/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteRole: function (params) {
        return request({
            url: "Role/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editRole: function (params, method) {
        return request({
            url: "Role/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getAllCompany: function (params) {
        return request({
            url: "Company/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addCompany: function (params) {
        return request({
            url: "Company/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteCompany: function (params) {
        return request({
            url: "Company/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editCompany: function (params, method) {
        return request({
            url: "Company/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getAllNotice: function (params) {
        return request({
            url: "Notice/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addNotice: function (params) {
        return request({
            url: "Notice/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteNotice: function (params) {
        return request({
            url: "Notice/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editNotice: function (params, method) {
        return request({
            url: "Notice/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getAllWorkshop: function (params) {
        return request({
            url: "Workshop/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addWorkshop: function (params) {
        return request({
            url: "Workshop/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteWorkshop: function (params) {
        return request({
            url: "Workshop/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editWorkshop: function (params, method) {
        return request({
            url: "Workshop/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getAllProcessCategory: function (params) {
        return request({
            url: "Processcategory/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    getAllProcessCategoryNolimit: function (params) {
        return request({
            url: "Processcategory/all",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteProcessCategory: function (params) {
        return request({
            url: "Processcategory/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    addProcessCategory: function (params) {
        return request({
            url: "Processcategory/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    editProcessCategory: function (params, method) {
        return request({
            url: "Processcategory/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getAllProcess: function (params) {
        return request({
            url: "Process/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    getAllProcessNolimit: function (params) {
        return request({
            url: "Process/all",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteProcess: function (params) {
        return request({
            url: "Process/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    addProcess: function (params) {
        return request({
            url: "Process/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    editProcess: function (params, method) {
        return request({
            url: "Process/edit",
            method: method,
            data: params,
            params: params
        })
    },
    getProcessAttr: function (params) {
        return request({
            url: "Processattr/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    getAllTask: function (params) {
        return request({
            url: "Task/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTask: function (params, method) {
        return request({
            url: "Task/edit",
            method: method,
            data: params,
            params: params
        })
    },
    addTask: function (params) {
        return request({
            url: "Task/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteTask: function (params) {
        return request({
            url: "Task/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    udateTaskAttr: function (params, method) {
        return request({
            url: "Task/updateTaskAttr",
            method: method,
            data: params,
            params: params
        })
    },

    getAllTasksales: function (params) {
        return request({
            url: "Tasksales/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTasksales: function (params) {
        return request({
            url: "Tasksales/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteTasksales: function (params) {
        return request({
            url: "Tasksales/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTasksales: function (params, method) {
        return request({
            url: "Tasksales/edit",
            method: method,
            data: params,
            params: params
        })
    },

    getAllTasknotification: function (params) {
        return request({
            url: "Tasknotification/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTasknotification: function (params) {
        return request({
            url: "Tasknotification/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteTasknotification: function (params) {
        return request({
            url: "Tasknotification/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTasknotification: function (params, method) {
        return request({
            url: "Tasknotification/edit",
            method: method,
            data: params,
            params: params
        })
    },
    chaijian: function (params, method) {
        return request({
            url: "Tasknotification/chaijian",
            method: method,
            data: params,
            params: params
        })
    },
    getAllTaskpaichan: function (params) {
        return request({
            url: "Taskpaichan/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTaskpaichan: function (params) {
        return request({
            url: "Taskpaichan/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteTaskpaichan: function (params) {
        return request({
            url: "Taskpaichan/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTaskpaichan: function (params, method) {
        return request({
            url: "Taskpaichan/edit",
            method: method,
            data: params,
            params: params
        })
    },

    getAllTaskshengchan: function (params) {
        return request({
            url: "Taskshengchan/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTaskshengchan: function (params) {
        return request({
            url: "Taskshengchan/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteTaskshengchan: function (params) {
        return request({
            url: "Taskshengchan/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTaskshengchan: function (params, method) {
        return request({
            url: "Taskshengchan/edit",
            method: method,
            data: params,
            params: params
        })
    },

    getAllTaskruku: function (params) {
        return request({
            url: "Taskruku/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTaskruku: function (params) {
        return request({
            url: "Taskruku/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteTaskruku: function (params) {
        return request({
            url: "Taskruku/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTaskruku: function (params, method) {
        return request({
            url: "Taskruku/edit",
            method: method,
            data: params,
            params: params
        })
    },


    getAllTaskxiaoshou: function (params) {
        return request({
            url: "Taskxiaoshou/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTaskxiaoshou: function (params) {
        return request({
            url: "Taskxiaoshou/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTaskxiaoshou: function (params, method) {
        return request({
            url: "Taskxiaoshou/edit",
            method: method,
            data: params,
            params: params
        })
    },



    getAllTaskwanjie: function (params) {
        return request({
            url: "Taskwanjie/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTaskwanjie: function (params) {
        return request({
            url: "Taskwanjie/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTaskwanjie: function (params, method) {
        return request({
            url: "Taskwanjie/edit",
            method: method,
            data: params,
            params: params
        })
    },




    getWorkerOrderProcess: function (params) {
        return request({
            url: "Workerorder/process",
            method: 'post',
            data: params,
            params: params
        })
    },
    getAllWorkerOrder: function (params) {
        return request({
            url: "Workerorder/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    getEnableWorkerOrder: function (params) {
        return request({
            url: "Workerorder/enable",
            method: 'post',
            data: params,
            params: params
        })
    },
    getWorkerOrderQrcode: function (params) {
        return request({
            url: "Workerorder/qrcode",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteWorkerOrder: function (params) {
        return request({
            url: "Workerorder/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    editWorkerOrder: function (params, method) {
        return request({
            url: "Workerorder/edit",
            method: method,
            data: params,
            params: params
        })
    },
    addWorkerOrder: function (params) {
        return request({
            url: "Workerorder/add",
            method: 'post',
            data: params,
            params: params
        })
    },

    getTemplateProcess: function (params) {
        return request({
            url: "Templateprocess/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    updateTemplateProcess: function (params) {
        return request({
            url: "Templateprocess/update",
            method: 'post',
            data: params,
            params: params
        })
    },
    getAllTemplate: function (params) {
        return request({
            url: "Template/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    addTemplate: function (params) {
        console.log(params)
        return request({
            url: "Template/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    editTemplate: function (params, method) {
        return request({
            url: "Template/edit",
            method: method,
            data: params,
            params: params
        })
    },
    deleteTemplate: function (params) {
        return request({
            url: "Template/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    addWkprocessaction: function (params) {
        return request({
            url: "Wkprocessaction/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    verifyWkprocessaction: function (params, method) {
        return request({
            url: "Wkprocessaction/verify",
            method: method,
            data: params,
            params: params
        })
    },
    getWkprocessaction: function (params) {
        return request({
            url: "Wkprocessaction/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    getTeamMember: function (params) {
        return request({
            url: "Manager/getTeamMember",
            method: 'post',
            data: params,
            params: params
        })
    },
    getAllManagerWorkerorder: function (params) {
        return request({
            url: "Managerworkerorder/list",
            method: 'post',
            data: params,
            params: params
        })
    },
    getManagerWorkerOrder: function (params) {
        return request({
            url: "Managerworkerorder/getManagerWorkerOrder",
            method: 'post',
            data: params,
            params: params
        })
    },
    addManagerWorkerorder: function (params) {
        return request({
            url: "Managerworkerorder/add",
            method: 'post',
            data: params,
            params: params
        })
    },
    deleteManagerWorkerorder: function (params) {
        return request({
            url: "Managerworkerorder/delete",
            method: 'post',
            data: params,
            params: params
        })
    },
    getWorkerOrderProcessAction: function (params) {
        return request({
            url: "Wkprocessaction/getWorkorderProcessAction",
            method: 'post',
            data: params,
            params: params
        })
    },
}
export default operate;