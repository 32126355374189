<template>
  <div style="height: 100%">
    <!--header-->
    <div class="header">
      <div class="bg_header">
        <div class="header_nav fl t_title">数据中心</div>
      </div>
    </div>

    <!--main-->
    <div class="data_content">
      <!-- <div class="data_time">
        温馨提示: 点击模块后跳转至详情页面。        
    </div> -->

      <div class="data_main">
        <div class="main_left fl">
          <div class="left_1 t_btn6" style="cursor: pointer">
            <!--左上边框-->
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
            </div>
            <!--右上边框-->
            <div class="t_line_box">
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
            </div>
            <!--左下边框-->
            <div class="t_line_box">
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
            </div>
            <!--右下边框-->
            <div class="t_line_box">
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="main_title">
              <img src="/static/bd/picture/t_1.png" alt="" />
              工单产出分析
            </div>
            <div
              id="chart_1"
              class="chart"
              style="width: 100%; height: 280px"
            ></div>
          </div>
          <div class="left_2" style="cursor: pointer">
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
            </div>
            <div class="t_line_box">
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
            </div>
            <div class="t_line_box">
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
            </div>
            <div class="t_line_box">
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="main_title">
              <img src="/static/bd/picture/t_2.png" alt="" />
              工单状态
            </div>
            <div
              id="chart_2"
              class="chart t_btn9"
              style="width: 100%; height: 280px"
            ></div>
          </div>
        </div>
        <div class="main_center fl" style="position: relative;">
          <div style="padding: 2px 5px;position: absolute;z-index: 99; margin-left: 10px; border: 1px solid #0e94ea;">
          <div
            style="
              width: 100px;
              height: 50px;
            "
          >
            <div
              style="
                color: #ffffff;
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
              "
            >
              在制工单计划数
            </div>
            <div id="unsuccess"
              style="
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                color: #6aac95;
              "
            >
              0
            </div>
          </div>
          <div
            style="
            margin-top: 10px;
              width: 100px;
              height: 50px;
            "
          >
            <div
              style="
                color: #ffffff;
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                border-top: 1px solid #0e94ea;
              "
            >
              在制工单延期数
            </div>
            <div id="delay"
              style="
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                color: #C35D86;
              "
            >
              0
            </div>
          </div>
          <div
            style="
            margin-top: 10px;
              width: 100px;
              height: 50px;
            "
          >
            <div
              style="
                color: #ffffff;
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                border-top: 1px solid #0e94ea;
              "
            >
              今日完成工单数
            </div>
            <div id="todaysuccess"
              style="
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                color: #48DEE3;
              "
            >
              0
            </div>
          </div>
          <div
            style="
            margin-top: 10px;
              width: 100px;
              height: 50px;
            "
          >
            <div
              style="
                color: #ffffff;
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                border-top: 1px solid #0e94ea;
              "
            >
              今日工单产出数
            </div>
            <div id="todayoutput"
              style="
                font-size: 12px;
                text-align: center;
                padding-top: 5px;
                color: #48DEE3;
              "
            >
              0
            </div>
          </div>
        </div>
          <!--中间-->
          <div class="data_c fl">
            <div class="data_c_1">
              <div id="contPar" class="contPar">
                <div id="page1" style="z-index: 1">
                  <div id="drag-container" style="z-index: 1">
                    <div id="spin-container">
                      <img
                        alt=""
                        class="infoBtn"
                        src="/static/bd/picture/page1_0.png"
                      />
                      <img
                        alt=""
                        class=""
                        src="/static/bd/picture/page1_1.png"
                      />
                      <img
                        alt=""
                        class=""
                        src="/static/bd/picture/page1_2.png"
                      />
                      <img
                        alt=""
                        class=""
                        src="/static/bd/picture/page1_1.png"
                      />
                      <img
                        alt=""
                        class=""
                        src="/static/bd/picture/page1_2.png"
                      />
                    </div>
                    <div id="ground"></div>
                  </div>

                  <img
                    alt=""
                    class="img3 png"
                    src="/static/bd/picture/newdz.png"
                  />
                </div>
              </div>
            </div>
            <div class="data_c_2 public">
              <div
                id="echart4"
                style="width: 100%; height: 100%; padding-top: 10px"
              ></div>
              <div class="boxfoot"></div>
            </div>
          </div>
        </div>
        <div class="main_right fr">
          <div class="right_1">
            <!--左上边框-->
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
            </div>
            <!--右上边框-->
            <div class="t_line_box">
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
            </div>
            <!--左下边框-->
            <div class="t_line_box">
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
            </div>
            <!--右下边框-->
            <div class="t_line_box">
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="main_title" style="width: 220px">
              <img src="/static/bd/picture/t_4.png" alt="" />
              不良品分析
            </div>
            <div
              id="chart_3"
              class="echart t_btn7"
              style="width: 100%; height: 280px"
            ></div>
          </div>
          <div class="right_2">
            <!--左上边框-->
            <div class="t_line_box">
              <i class="t_l_line"></i>
              <i class="l_t_line"></i>
            </div>
            <!--右上边框-->
            <div class="t_line_box">
              <i class="t_r_line"></i>
              <i class="r_t_line"></i>
            </div>
            <!--左下边框-->
            <div class="t_line_box">
              <i class="l_b_line"></i>
              <i class="b_l_line"></i>
            </div>
            <!--右下边框-->
            <div class="t_line_box">
              <i class="r_b_line"></i>
              <i class="b_r_line"></i>
            </div>
            <div class="main_title" style="width: 200px">
              <img src="/static/bd/picture/t_5.png" alt="" />
              不良产品分布
            </div>
            <div
              id="chart_4"
              class="chart"
              style="width: 100%; height: 280px"
            ></div>
          </div>
        </div>
      </div>
      <div class="data_bottom">
        <div class="bottom_1 fl t_btn5" style="cursor: pointer">
          <div class="t_line_box">
            <i class="t_l_line"></i>
            <i class="l_t_line"></i>
          </div>
          <div class="t_line_box">
            <i class="t_r_line"></i>
            <i class="r_t_line"></i>
          </div>
          <div class="t_line_box">
            <i class="l_b_line"></i>
            <i class="b_l_line"></i>
          </div>
          <div class="t_line_box">
            <i class="r_b_line"></i>
            <i class="b_r_line"></i>
          </div>
          <div class="main_title">
            <img src="/static/bd/picture/t_7.png" alt="" />
            工单概况
          </div>
          <div class="main_table t_btn8">
            <table class="commonTable">
              <thead>
                <tr>
                  <td>工单编号</td>
                  <td>产品名称</td>
                  <td>状态</td>
                  <td>计划产量</td>
                  <td>下单时间</td>
                  <td>预估时间</td>
                  <td>完成时间</td>
                </tr>
              </thead>
              <tbody id="wkbody"></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    let jquery = document.createElement("script");
    jquery.type = "text/javascript";
    jquery.src = "https://cdn.bootcdn.net/ajax/libs/jquery/3.6.3/jquery.js";
    document.body.appendChild(jquery);
    let echarts = document.createElement("script");
    echarts.type = "text/javascript";
    echarts.src = "/static/bd/scripts/echarts.min.js";
    document.body.appendChild(echarts);
    setTimeout(function () {
      let indexscript = document.createElement("script");
      indexscript.type = "text/javascript";
      indexscript.src = "/static/bd/scripts/index.js?id=002";
      document.body.appendChild(indexscript);
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "/static/bd/scripts/script.js";
      document.body.appendChild(script);
    }, 1000);
  },
};
</script>
    <style scoped>
@import "../../../public/static/bd/css/base.css";
@import "../../../public/static/bd/css/index.css";
@import "../../../public/static/bd/css/main.css";
@import "../../../public/static/bd/css/style.css";
div {
  color: #333;
  background-color: #1f2327;
}
.t_title {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2.5em;
  line-height: 80px;
  color: #fff;
}

#chart_map {
  cursor: pointer;
}

.t_show {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
  background: #2c58a6;
  padding: 2px 5px;
  color: #fff;
  cursor: pointer;
}
.linshi_zdy {
  position: absolute;
  right: 0px;
  top: 450px;
}

.linshi_zdy li {
  width: 150px;
  font-size: 16px;
  padding: 3px 8px;
  cursor: pointer;
}

.linshi_zdy span {
  display: block;
  width: 14px;
  height: 14px;
  float: left;
  border-radius: 50%;
  margin-top: 3px;
  margin-right: 5px;
}

.linshi_zdy li:first-child {
  color: #ff0000;
}

.linshi_zdy li:first-child span {
  background: #ff0000;
}

.linshi_zdy li:nth-child(2) {
  color: #9cff00;
}

.linshi_zdy li:nth-child(2) span {
  background: #9cff00;
}

.linshi_zdy li:nth-child(3) {
  color: #fff;
}

.linshi_zdy li:nth-child(3) span {
  background: #fff;
}

.linshi_zdy li:nth-child(4) {
  color: #f4a100;
}

.linshi_zdy li:nth-child(4) span {
  background: #f4a100;
}
</style>