var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-content"},[_c('Breadcrumb',{attrs:{"addpath":"/TaskNotification/add","crumb":_vm.crumb,"showButton":false}}),_c('el-form',[_c('el-form-item',{attrs:{"label":"关键字","prop":"name"}},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入","clearable":"","size":"small"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('el-button',{staticClass:"searchbtn",attrs:{"slot":"append"},on:{"click":_vm.searchput},slot:"append"},[_vm._v("搜索")])],1)],1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"max-height":"550"}},[_c('el-table-column',{attrs:{"prop":"id","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"title","label":"销售单标题"}}),_c('el-table-column',{attrs:{"prop":"client","label":"委托单位"}}),_c('el-table-column',{attrs:{"prop":"product_name","label":"产品"}}),_c('el-table-column',{attrs:{"prop":"add_manager","label":"上传人"}}),_c('el-table-column',{attrs:{"prop":"add_time","label":"上传时间"}}),_c('el-table-column',{attrs:{"prop":"urgent_text","label":"紧急状态"}}),_c('el-table-column',{attrs:{"prop":"status_text","label":"状态"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.category_id == 2)?_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.chaijian(scope.$index)}}},[_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.$options.filters.chaijianAction(scope.row.chaijian_status)
            )}})]):_vm._e(),(scope.row.category_id == 2)?_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.jihua(scope.$index)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.jihuaAction(scope.row.jihua_status))}})]):_vm._e(),(
            (scope.row.category_id == 2 &&
              scope.row.task_notification_status != 0 &&
              scope.row.status == 0 &&
              scope.row.chaijian_status == 2 &&
              scope.row.jihua_status == 2 &&
              _vm.userInfo.auth.includes('handleNotification')) ||
            (scope.row.category_id == 1 &&
              scope.row.task_notification_status != 0 &&
              scope.row.status == 0 &&
              _vm.userInfo.auth.includes('handleNotification'))
          )?_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.add(scope.$index)}}},[_c('div',{staticStyle:{"color":"#67c23a"}},[_vm._v("安排排产")])]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"small"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.edit(scope.$index)}}},[_vm._v(" 通知单详情 ")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total},on:{"current-change":_vm.getList}}),_c('el-dialog',{attrs:{"title":"拆检信息","visible":_vm.chaijianVisible,"width":"80%"},on:{"update:visible":function($event){_vm.chaijianVisible=$event}}},[_c('chaijian',{attrs:{"info":_vm.info}})],1),_c('el-dialog',{attrs:{"title":"计划信息","visible":_vm.jihuaVisible,"width":"80%"},on:{"update:visible":function($event){_vm.jihuaVisible=$event}}},[_c('jihua',{attrs:{"info":_vm.info}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }