import axios from "axios";
import store from "../store"
import { Message,Loading } from "element-ui"
 // loading开始 方法
 var loading;
 function startLoading() {  
  // element-ui loading 服务调用方式  
      loading = Loading.service({ 
          lock: true,
          text: '拼命加载中...',
          spinner: 'el-icon-loading'  // 自定义图标
         // background: '#ffffff'
      })
  }
  
  // loading结束 方法
  function endLoading() {  
      loading.close()

    }
const request = axios.create({
    baseURL: '/api/',
    timeout: 20000
})
request.interceptors.request.use(function (config) {
    if (store.state.user.token) {
        config['token'] = store.stateuser.token;
    }
   if(config['url']!='login'){
    
      let userInfo=localStorage.getItem('userInfo')
      let jsonUserInfo=JSON.parse(userInfo)
      if(!jsonUserInfo||jsonUserInfo=='undefined'){
        window.location.href="/"
      }else{
        if(!config.hasOwnProperty('params'))
        {
          config['params']={}
        }
        let _data=[];
        if(!config.hasOwnProperty('data')){
          config['data']=''
        }else{
           _data=config['data']
        }

        _data['manager_id']=jsonUserInfo.id
        config['data']=_data
        config['params']['manager_id']=jsonUserInfo.id
      }
   }
   startLoading(); 
    return config;
}, function (error) {
  endLoading();
    return Promise.reject(error)
});
request.interceptors.response.use(function (res) {
  endLoading();
    let response = res.data;
    if(response.code!=200){
        Message({
                type: 'error',
                message: response.msg||"error",
                duration:1000,
                showClose:true
        });
        if (res.code === 401) {
            MessageBox.confirm('重新登录或者继续留在该页面', '确认', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              store.dispatch('user/resetToken').then(() => {
                location.reload()
              })
            })
          }
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});
export default request;