<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/material/add"
      :crumb="crumb"
      :showButton="true"
    ></Breadcrumb>
    <el-form ref="ruleForm" :model="form" label-width="80px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <div>
        <el-form-item label="分类">
          <el-select
            class="el-select"
            v-model="form.category_id"
            placeholder="选择分类"
          >
            <el-option
              v-for="item in form.process_category"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="小组">

        <el-cascader
            :options="roles"
            :props="{ checkStrictly: true }"
            :show-all-levels="false"
            @change="roleChange"
            clearable>
      </el-cascader>
      </el-form-item>
      <el-form-item label="描述">
        <el-input
          style="width: 400px"
          type="textarea"
          v-model="form.description"
        ></el-input>
      </el-form-item>
      <el-form-item label="用工">
        <el-input v-model="form.labor"></el-input>
      </el-form-item>
      <div v-for="item in form.select_material_id" :key="item.id">
        <el-form-item label="原材料">
          <el-select
            class="el-select"
            v-model="item.material_id"
            placeholder="选择原材料"
          >
            <el-option
              v-for="item in form.material"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="item.value"
            class="el-input"
            placeholder="请填属性值"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button @click="addInput">新增原材料</el-button>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
        role_id:"",
        category_id:'',
        labor:0,
        select_material_id: [{ id: "", material_id: "", value: "" }],
        material: [],
        process_category:[]
      },
      roles:[],
      crumb: ["工单步骤", "添加"],
    };
  },
  mounted() {
    operate.getAllMaterial({ is_all: true }).then((res) => {
      this.form.material = res.data;
    });
    operate.getAllProcessCategory({ is_all: true }).then((res) => {
      this.form.process_category = res.data;
    });
    operate.getRoleTree({}).then((response)=>{
        if(response.code=='200'){
         this.roles=response.data
        }
     })
  },
  methods: {
    roleChange(value){
      this.form.role_id=value[value.length-1];
    },
    addInput() {
      this.form.select_material_id.push({
        id: Date.now(),
        material_id: "",
        value: "",
      });
    },
    onSubmit() {
      delete this.form.material;
      operate.addProcess(this.form).then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.el-input {
  width: 200px;
}
</style>