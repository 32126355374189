<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="标题" show-overflow-tooltip min-width="25%" align="center">
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div style="font-weight:normal">
            {{ form[0].taskNotificationInfo.title }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="委托单位" show-overflow-tooltip min-width="25%" align="center">
      </el-table-column>
      <el-table-column align="center" min-width="25%">
        <template #header>
          <div style="font-weight:normal">
            {{ form[0].taskNotificationInfo.client }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="到货日期" show-overflow-tooltip min-width="25%" align="center">
      </el-table-column>
      <el-table-column align="center" min-width="25%">
        <template #header>
          <div style="font-weight:normal">
            {{ form[0].taskNotificationInfo.arrival_time }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="用户要求" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="center">
        <template #header>
          <div style="font-weight:normal">
            {{ form[0].taskNotificationInfo.remarks }}
          </div>
        </template>
      </el-table-column>
    </el-table>


    <el-table
      ref="historyTable"
      :data="form"
      style="width: 100%"
      size="small"
      :border="true"
    >
      <el-table-column prop="name" label="设备名称" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.product_name}}
            
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="model" label="型号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.model}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="power" label="功率" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.power}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="num" label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            {{scope.row.num}}
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="output_num" label="实际产量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.output_num"
              style="width: 100%"
              :disabled="true"
            ></el-input>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column label="附件" show-overflow-tooltip min-width="16.99%" align="center">
      </el-table-column>
      <el-table-column align="left">
        <template #header>
          <el-image
          v-for="item in form[0].taskNotificationInfo.appendix"
          style="width: 100px; height: 100px"
          :src="item"
          :key="item"
          :preview-src-list="[item]"
        ></el-image>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<style>
.el-table__empty-block{
  display: none !important;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      fileList: [],
      rowCount: 1,
      status:0,
      form:[],
      crumb: ["任务通知单", "详情"],
    };
  },
  mounted() {
    let param = this.$route.params;
      operate.editTasknotification(param, "get").then((response) => {
        if (response.code == "200") {
          let info = response.data;
          this.form = info;
          console.log(this.form)
        }
      });

  },
  methods: {
  
    onSubmit() {

    },
  },
};
</script>