<template>
  <div class="right-content">
    <Breadcrumb addpath="/material/add" :crumb="crumb" :showButton="false"></Breadcrumb>
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="workerorder_sn" label="工单号"> </el-table-column>
      <el-table-column prop="workerorder_name" label="工单标题"> </el-table-column>
      <el-table-column prop="product_name" label="产品名称"> </el-table-column>
      <el-table-column prop="process_name" label="工步骤名称"> </el-table-column>
      <el-table-column prop="manager_name" label="提交人"> </el-table-column>
      <el-table-column prop="state" label="提交状态"> </el-table-column>
      <el-table-column prop="add_time" label="提交日期"> </el-table-column>
      <el-table-column prop="verify_status_text" label="审核状态"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="verify(scope.$index)"
            type="text"
            size="small"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
<script>
import operate from "../../api/api"
export default {
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      crumb:['工单审核','列表']
    };
  },
  methods: {
    verify(index) {
      let id = this.tableData[index].id;
      let path = "/wkprocessaction/verify/" + id;
      this.$router.push(path);
    },
    getList(p) {
      operate.getWkprocessaction({page:p}).then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
    },
  },
  mounted() {
    operate.getWkprocessaction().then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
  }
};
</script>