<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/workerorder/add"
      :crumb="crumb"
      :showButton="true"
    ></Breadcrumb>
    <el-form :inline="true" :model="listQuery">
      <el-form-item label="工单状态">
        <el-select v-model="listQuery.state" placeholder="请选择">
          <el-option
            v-for="item in stateOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类别">
        <el-select v-model="listQuery.product_type" placeholder="请选择">
          <el-option
            v-for="item in categoryOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="委托单位">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="listQuery.client"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="功率">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="listQuery.power"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="型号">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="listQuery.model"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="工单号">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="listQuery.sn"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
      <el-table-column fixed prop="sn" label="工单号" width="100px">
      </el-table-column>
      <el-table-column prop="name" label="标题"> </el-table-column>
      <el-table-column prop="product_name" label="产品"> </el-table-column>
      <el-table-column prop="add_manager" label="创建人"> </el-table-column>
      <el-table-column prop="add_time" label="创建时间"> </el-table-column>
      <el-table-column prop="eta_time" label="预估时间"> </el-table-column>
      <el-table-column prop="end_time" label="完成时间"> </el-table-column>
      <el-table-column prop="template_info.name" label="所属模板">
      </el-table-column>
      <el-table-column prop="state_text" label="状态"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="assign(scope.$index)"
            type="text"
            size="small"
          >
            分配人员
          </el-button>
          <el-button
            @click.native.prevent="deleteRow(scope.$index)"
            type="text"
            size="small"
          >
            移除
          </el-button>
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
          <el-button
            @click.native.prevent="handleworkerorder(scope.$index)"
            type="text"
            size="small"
          >
            处理工单
          </el-button>
          <el-button
            :data-index="scope.$index"
            @click.native.prevent="qrcode(scope.$index)"
            type="text"
            size="small"
          >
            打印二维码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
    <el-dialog :visible.sync="dialogVisible" width="500px">
      <div id="print">
        <el-image style="width: 100%" :src="poster"></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-print="printObj">打印</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import operate from "../../api/api";
export default {
  data() {
    return {
      dialogVisible: false,
      printObj: {
        id: "print", // 这里是要打印元素的ID
        popTitle: "工单", // 打印的标题
        extraCss: "", // 打印可引入外部的一个 css 文件
        extraHead: "", // 打印头部文字
        beforeOpenCallback(e) {},
      },
      listQuery: {
        page:1,
        state: "",
        sn: "",
        product_type:'',
        client:'',
        power:'',
        model:''
      },
      poster: "",
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      crumb: ["工单", "列表"],
      categoryOption: [
        {
          value: "",
          label: "不限",
        },
        {
          value: "1",
          label: "电机",
        },
        {
          value: "2",
          label: "变压器",
        },
        {
          value: "3",
          label: "开关",
        },
        {
          value: "4",
          label: "变频器",
        },
      ],
      stateOption: [
        {
          value: "",
          label: "不限",
        },
        {
          value: "running",
          label: "进行中",
        },
        {
          value: "warning",
          label: "挂起",
        },
        {
          value: "success",
          label: "完成",
        },
      ],
      state: "",
    };
  },
  methods: {
    process(index) {
      let id = this.tableData[index].id;
      let template_id = this.tableData[index].template_id;
      let path = this.$route.path + "/process/" + id + "/" + template_id;
      this.$router.push(path);
    },
    handleworkerorder(index) {
      let id = this.tableData[index].id;
      let template_id = this.tableData[index].template_id;
      let path =
        this.$route.path + "/handleworkerorder/" + id + "/" + template_id;
      this.$router.push(path);
    },
    deleteRow(index) {
      let _info = this.tableData[index];
      this.$confirm("确定要删除工单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
      })
        .then(() => {
          operate.deleteWorkerOrder({ id: _info.id }).then((response) => {
            if (response.code == "200") {
              this.$message({
                message: response.msg,
                type: "success",
                onClose: function () {
                  window.location.reload();
                },
              });
            }
          });
        })
        .catch(() => {});
    },
    qrcode(index) {
      let _info = this.tableData[index];
      operate
        .getWorkerOrderQrcode({
          workerorder_id: _info.id,
          template_id: _info.template_id,
        })
        .then((response) => {
          if (response.code == "200") {
            this.poster = response.path;
            this.dialogVisible = true;
          }
        });
    },
    searchput(e) {
      this.listQuery.page=1
      operate
        .getAllWorkerOrder(this.listQuery)
        .then((response) => {
          this.tableData = response.data;
          this.total = response.count;
        });
    },
    edit(index) {
      let id = this.tableData[index].id;
      let path = this.$route.path + "/edit/" + id;
      this.$router.push(path);
    },
    assign(index) {
      let workerorder_id = this.tableData[index].id;
      let path = "/managerworkerorder/managerworkerorder/" + workerorder_id;
      this.$router.push(path);
    },
    getList(p) {
      this.listQuery.page=p;
      operate.getAllWorkerOrder(this.listQuery).then((response) => {
        this.tableData = response.data;
        this.total = response.count;
      });
    },
  },
  mounted() {
    operate.getAllWorkerOrder().then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
  },
};
</script>