<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/role/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column
        label="标题"
        show-overflow-tooltip
        min-width="25%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input v-model="form.title" />
        </template>
      </el-table-column>
      <el-table-column
        label="委托单位"
        show-overflow-tooltip
        min-width="25%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="right" min-width="25%">
        <template #header>
          <el-input v-model="form.client" />
        </template>
      </el-table-column>
    </el-table>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>

      <el-table-column
        label="到货日期"
        show-overflow-tooltip
        min-width="25%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="right" min-width="25%">
        <template #header>
          <el-date-picker
            v-model="form.arrival_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </template>
      </el-table-column>

      <el-table-column
        label="类别"
        show-overflow-tooltip
        min-width="25%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="right" min-width="25%">
        <template #header>
          <el-select v-model="form.category_id" placeholder="请选择">
            <el-option
              v-for="item in form.category"
              :key="item.category_id"
              :label="item.name"
              :value="item.category_id"
            />
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <el-table style="width: 100%" :border="true">
      <template slot="empty">
        <p></p>
      </template>
      <el-table-column
        label="用户要求"
        show-overflow-tooltip
        min-width="16.99%"
        align="center"
      >
      </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input v-model="form.remarks" type="textarea" :rows="4" />
        </template>
      </el-table-column>
    </el-table>

    <el-table
      ref="historyTable"
      :data="form.detail"
      style="width: 100%"
      size="small"
      :border="true"
    >
      <el-table-column
        prop="name"
        label="设备名称"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <el-select v-model="scope.row.product_id" placeholder="请选择">
            <el-option
              v-for="item in scope.row.product"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="product_type" label="类别" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.product_type" placeholder="请选择">
            <el-option
              v-for="item in scope.row.productType"
              :key="item.product_type"
              :label="item.name"
              :value="item.product_type"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="model" label="型号" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.model"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="power" label="功率" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.power"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="num" label="数量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.num"
              style="width: 100%"
            ></el-input>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="output_num" label="实际产量" align="center">
        <template slot-scope="scope">
          <div class="input-box">
            <el-input
              size="small"
              v-model="scope.row.output_num"
              style="width: 100%"
              :disabled="true"
            ></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="onDelLayer(scope.$index, scope.row)"
            v-if="scope.$index"
            >[-]</el-button
          >
          <el-button
            type="text"
            @click="onAddLayer"
            v-if="scope.$index === rowCount - 1"
            >[+]</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0px">
      <el-upload
        class="upload-demo"
        action="/api/Upload/uploadImage"
        :on-preview="uploadPreview"
        :on-remove="uploadRemove"
        :on-success="uploadCallBack"
        multiple
        :file-list="fileList"
        list-type="picture-card"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <div style="text-align: center">
      <el-button type="primary" @click="onSubmit">立即提交</el-button>
    </div>
  </div>
</template>
<style>
.el-table__empty-block {
  display: none !important;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      formLabelWidth: "120px",
      fileList: [],
      rowCount: 1,
      product: [],
      form: {
        category: [
          {
            category_id: 1,
            name: "生产类",
          },
          {
            category_id: 2,
            name: "检修类",
          },
        ],
        category_id: "",
        title: "",
        client: "",
        arrival_time: "",
        remarks: "",
        appendix: "",
        detail: [
          {
            product_id: "",
            product_type: "",
            product: [],
            productType: [
              {
                product_type: 1,
                name: "电机",
              },
              {
                product_type: 2,
                name: "变压器",
              },
              {
                product_type: 3,
                name: "开关",
              },
              {
                product_type: 4,
                name: "变频器",
              },
            ],
            name: "",
            model: "",
            power: "",
            num: "",
          },
        ],
      },
      crumb: ["销售单", "添加"],
    };
  },
  mounted() {
    let that = this;
    operate.getAllProduct().then((response) => {
      that.product = response.data;
      that.form.detail[0].product = that.product;
    });
  },
  methods: {
    uploadRemove(file, fileList) {
      let _fileList = [];
      console.log("remove file");
      console.log(file);
      this.fileList.forEach((item) => {
        if (item.name != file.name) {
          _fileList.push(item);
        }
      });
      this.fileList = _fileList;
    },
    uploadPreview(file) {
      console.log(file);
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response._path,
          path: response.path,
          url: response.path,
        };
        this.fileList.push(_obj);
      }
    },
    onAddLayer() {
      var that = this;
      if (that.form.detail) {
        that.form.detail.push({
          name: "",
          model: "",
          power: "",
          num: "",
          product: that.product,
          productType: [
            {
              product_type: 1,
              name: "电机",
            },
            {
              product_type: 2,
              name: "变压器",
            },
            {
              product_type: 3,
              name: "开关",
            },
            {
              product_type: 4,
              name: "变频器",
            },
          ],
        });
      } else {
        that.form.detail = [
          {
            name: "",
            model: "",
            power: "",
            num: "",
            product: that.product,
            productType: [
              {
                product_type: 1,
                name: "电机",
              },
              {
                product_type: 2,
                name: "变压器",
              },
              {
                product_type: 3,
                name: "开关",
              },
              {
                product_type: 4,
                name: "变频器",
              },
            ],
          },
        ];
      }
      this.rowCount = that.form.detail.length;
    },
    onDelLayer(index, row) {
      this.form.detail.splice(index, 1);
      this.rowCount = this.detail.length;
    },
    onSubmit() {
      if (this.fileList.length > 0) {
        for (let k in this.fileList) {
          this.form.appendix += this.fileList[k].name + ";";
        }
      }
      operate.addTasksales(this.form).then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
  },
};
</script>