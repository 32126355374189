<template>
  <div class="block">
    <el-timeline>
      <div v-for="item in actionList" :key="item.id">
      <el-timeline-item :timestamp="item.add_time+' '+item.user_info.name" placement="top">
        <el-card>
          <el-image
          v-for="_item in item.images"
          style="width: 100px; height: 100px"
          :src="_item"
          :key="_item"
          :preview-src-list="[_item]"
        ></el-image>
        <p style="margin-bottom:5px">提交状态：<span :style="{color:item.state=='success'?'green':'red'}">{{item.state | formatSubmitStatus}}</span></p>
        <p>提交说明：{{ item.mask }}</p>
        </el-card>
        <div style="margin-top:10px;" v-if="item.verify_manager_id">
        <el-card style="background-color: #e1f3d8;">
          <p style="margin-bottom:5px">审核状态：<span :style="{color:item.verify_status==1?'green':'red'}">{{item.verify_status | formatVerifyStatus}}</span></p>
          <p style="margin-bottom:5px">审核意见：{{ item.verify_mask }}</p>
          <p>{{ item.verify_user_info.name }} 审核于 {{ item.verify_time }}</p>
        </el-card>
      </div>
      </el-timeline-item>
    </div>
    </el-timeline>
  </div>
</template>
    <script>
import operate from "../../api/api.js";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      actionList:[]
    };
  },
  props: ["workerorderId", "processId"],
  created() {
    this.workerorder_id = this.workerorderId;
    this.process_id = this.processId;
    this.getList();
  },
  watch: {
    workerorderId(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.workerorder_id = newval;
      this.getList();
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
    processId(newval, oldval) {
      let loadingInstance = Loading.service({
        text: "数据获取中",
      });
      this.process_id = newval;
      this.getList();
      setTimeout(() => {
        loadingInstance.close();
      }, 2000);
    },
  },
  filters:{
    formatVerifyStatus(value) {
        if (value == 1) {
          return '同意';
        } else if (value == -1) {
          return '审核未通过';
        } else {
          return '待审核';
        }
      },
      formatSubmitStatus(value) {
        if (value == 'success') {
          return '完成';
        } else if (value == 'error') {
          return '挂起';
        } else {
          return '进行中';
        }
      },
  },
  mounted() {},
  methods: {
    getList() {
      operate
        .getWorkerOrderProcessAction({
          workerorder_id: this.workerorder_id,
          process_id: this.processId,
        })
        .then((response) => {
           this.actionList=response.data
        });
    },
  },
};
</script>