<template>
  <div class="flow-menu" ref="tool">
    <div v-for="menu in menuList" :key="menu.id">
      <ul v-show="menu.open" class="ef-node-menu-ul">
        <draggable
          @end="end"
          @start="move"
          v-model="menu.children"
          :options="draggableOptions"
        >
          <template v-for="subMenu in menu.children">
            <li
              class="ef-node-menu-li"
              :key="subMenu.id"
              :type="subMenu.type"
              v-if="subMenu.show"
            >
              <i :class="subMenu.ico"></i> {{ subMenu.name }}
            </li>
          </template>
        </draggable>
      </ul>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import operate from "../../api/api.js";
var mousePosition = {
  left: -1,
  top: -1,
};

export default {
  data() {
    return {
      activeNames: "1",
      // draggable配置参数参考 https://www.cnblogs.com/weixin186/p/10108679.html
      draggableOptions: {
        preventOnFilter: false,
        sort: false,
        disabled: false,
        ghostClass: "tt",
        // 不使用H5原生的配置
        forceFallback: true,
        // 拖拽的时候样式
        fallbackClass: "flow-node-draggable",
      },
      // 默认打开的左侧菜单的id
      defaultOpeneds: ["1", "2"],
      menuListNew: [],
      menuList: [
        {
          id: "1",
          type: "group",
          name: "工单步骤",
          ico: "el-icon-video-play",
          open: true,
          children: [
          ],
        },
      ],
      nodeMenu: {},
    };
  },
  components: {
    draggable,
  },
  mounted() {
    operate.getAllProcessNolimit().then((res) => {
      if (res.code == "200") {
        let _data = res.data;
        this.menuList[0].children = [];
        let _children=[]
        for (let i = 0; i < _data.length; i++) {
            _children[i] = {};
            _children[i]._id = _data[i]["id"].toString();
            _children[i].type = "end";
            _children[i].name = _data[i]["title"];
            _children[i].ico = "el-icon-caret-right";
            _children[i].state=_data[i]['state'];
            _children[i].show = 1;
            _children[i].style = {};
        }
        this.menuList[0].children=_children
        this.$nextTick(function () {
        this.$on("releaseNode", function (p) {
            let nodeId = p.nodeId;
            for (let i = 0; i < this.menuList[0].children.length; i++) {
            if (this.menuList[0].children[i]._id == nodeId) {
                this.menuList[0].children[i].show = 1;
            }
            }
        });
        this.$on("hideNode", function (p) {
            let nodeId = p.nodeId;
            for (let i = 0; i < this.menuList[0].children.length; i++) {
            if (this.menuList[0].children[i]._id == nodeId) {
              console.log(this.menuList[0].children[i])
                // this.menuList[0].children[i].show = 0;
            }
            }
        });
        });
      }
    });

  },
  created() {
    /**
     * 以下是为了解决在火狐浏览器上推拽时弹出tab页到搜索问题
     * @param event
     */
    if (this.isFirefox()) {
      document.body.ondrop = function (event) {
        // 解决火狐浏览器无法获取鼠标拖拽结束的坐标问题
        mousePosition.left = event.layerX;
        mousePosition.top = event.clientY - 50;
        event.preventDefault();
        event.stopPropagation();
      };
    }
  },
  methods: {
    // 根据类型获取左侧菜单对象
    getMenuByName(name) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (
            children[j].name.replace(/^\s\s*/, "").replace(/\s\s*$/, "") ===
            name.replace(/^\s\s*/, "").replace(/\s\s*$/, "")
          ) {
            return children[j];
          }
        }
      }
    },
    hideNode(nodeId) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i]._id == nodeId) {
          this.menuList[i].show = 0;
        }
      }
    },
    // 拖拽开始时触发
    move(evt, a, b, c) {
      var name = evt.item.outerText;
      this.nodeMenu = this.getMenuByName(name);
    },
    // 拖拽结束时触发
    end(evt, e) {
      this.$emit("addNode", evt, this.nodeMenu, mousePosition);
    },
    // 是否是火狐浏览器
    isFirefox() {
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("Firefox") > -1) {
        return true;
      }
      return false;
    },
  },
};
</script>
