import Vue from 'vue'
import VueRouter from 'vue-router'

import login from '@/views/login/login'

import material from '@/views/material/material'
import materialadd from '@/views/material/add'
import materialedit from '@/views/material/edit'

import role from '@/views/role/list'
import roleadd from '@/views/role/add'
import roleedit from '@/views/role/edit'

import company from '@/views/company/list'
import companyadd from '@/views/company/add'
import companyedit from '@/views/company/edit'

import notice from '@/views/notice/list'
import noticeadd from '@/views/notice/add'
import noticeedit from '@/views/notice/edit'

import workshop from '@/views/workshop/list'
import workshopadd from '@/views/workshop/add'
import workshopedit from '@/views/workshop/edit'

import product from '@/views/product/list'
import productadd from '@/views/product/add'
import productedit from '@/views/product/edit'

import bd from '@/views/bd/bd'

import manager from '@/views/manager/manager'
import manageredit from '@/views/manager/edit'
import managereditinfo from '@/views/manager/editinfo'
import manageradd from '@/views/manager/add'


import managerworkerorder from '@/views/managerworkerorder/managerworkerorder'

import process from '@/views/process/process'
import processadd from '@/views/process/add'
import processedit from '@/views/process/edit'

import processcategory from '@/views/processcategory/list'
import processcategoryadd from '@/views/processcategory/add'
import processcategoryedit from '@/views/processcategory/edit'

import task from '@/views/task/task'
import taskadd from '@/views/task/add'
import taskedit from '@/views/task/edit'
import taskhandltask from '@/views/task/handltask'



import tasksales from '@/views/tasksales/list'
import tasksalesadd from '@/views/tasksales/add'
import tasksalesedit from '@/views/tasksales/edit'


import tasknotification from '@/views/tasknotification/list'
import tasknotificationadd from '@/views/tasknotification/add'
import tasknotificationedit from '@/views/tasknotification/edit'

import chaiyan from '@/views/chaiyan/list'
import chaiyanadd from '@/views/chaiyan/add'
import chaiyanedit from '@/views/chaiyan/edit'


import taskpaichan from '@/views/taskpaichan/list'
import taskpaichanadd from '@/views/taskpaichan/add'
import taskpaichanedit from '@/views/taskpaichan/edit'

import taskshengchan from '@/views/taskshengchan/list'
import taskshengchanadd from '@/views/taskshengchan/add'
import taskshengchanedit from '@/views/taskshengchan/edit'

import taskruku from '@/views/taskruku/list'
import taskrukuadd from '@/views/taskruku/add'
import taskrukuedit from '@/views/taskruku/edit'

import taskxiaoshou from '@/views/taskxiaoshou/list'
import taskxiaoshouedit from '@/views/taskxiaoshou/edit'

import taskwanjie from '@/views/taskwanjie/list'
import taskwanjieedit from '@/views/taskwanjie/edit'

import main from '@/views/main/main'


import templatelist from '@/views/template/template'
import templateadd from '@/views/template/add'
import templateedit from '@/views/template/edit'

import workerorder from '@/views/workerorder/list'
import workerorderedit from '@/views/workerorder/edit'
import workerorderadd from '@/views/workerorder/add'
import handleworkerorder from '@/views/workerorder/handleworkerorder'

import wkprocessaction from '@/views/wkprocessaction/list'
import wkprocessactionverify from '@/views/wkprocessaction/verify'

Vue.use(VueRouter)
//创建路由组件，将路由和组件进行映射
const routes = [

    {
        path: '/',
        name: 'login',
        component: login,

    },
    {
        path: '/',
        component: main,
        children: [
            {
                path: '',
                component: bd
            },
            {
                path: '/material',
                component: material
            },
            {
                path: '/material/add',
                component: materialadd
            },
            {
                path: '/material/edit/:id',
                component: materialedit
            },
            {
                path: '/notice',
                component: notice
            },
            {
                path: '/notice/add',
                component: noticeadd
            },
            {
                path: '/notice/edit/:id',
                component: noticeedit
            },
            
            {
                path: '/company',
                component: company
            },
            {
                path: '/company/add',
                component: companyadd
            },
            {
                path: '/company/edit/:id',
                component: companyedit
            },
            {
                path: '/workshop',
                component: workshop
            },
            {
                path: '/workshop/add',
                component: workshopadd
            },
            {
                path: '/workshop/edit/:id',
                component: workshopedit
            },
            {
                path: '/role',
                component: role
            },
            {
                path: '/role/add',
                component: roleadd
            },
            {
                path: '/role/edit/:id',
                component: roleedit
            },

            {
                path: '/product',
                component: product
            },
            {
                path: '/product/add',
                component: productadd
            },
            {
                path: '/product/edit/:id',
                component: productedit
            },


            {
                path: '/template',
                component: templatelist
            },
            {
                path: '/template/add',
                component: templateadd
            },
            {
                path: '/template/edit/:id',
                component: templateedit
            },
            {
                path: '/workerorder',
                component: workerorder
            },
            {
                path: '/workerorder/edit/:id',
                component: workerorderedit
            },
            {
                path: '/workerorder/add',
                component: workerorderadd
            },
            {
                path: '/workerorder/handleworkerorder/:workerorder_id/:template_id/',
                component: handleworkerorder
            },


            {
                path: '/bd',
                component: bd
            },

            {
                path: '/manager',
                component: manager
            },
            {
                path: '/manager/edit/:id',
                component: manageredit
            },
            {
                path: '/manager/editinfo/:id',
                component: managereditinfo
            },
            {
                path: '/manager/edit/',
                component: manageredit
            },
            {
                path: '/manager/add',
                component: manageradd
            },


            {
                path: '/managerworkerorder/managerworkerorder/:workerorder_id',
                component: managerworkerorder
            },
            {
                path: '/managerworkerorder/managerworkerorder',
                component: managerworkerorder
            },


            {
                path: '/processcategory',
                component: processcategory
            },
            {
                path: '/processcategory/add',
                component: processcategoryadd
            },
            {
                path: '/processcategory/edit/:id',
                component: processcategoryedit
            },

            {
                path: '/process',
                component: process
            },
            {
                path: '/process/add',
                component: processadd
            },
            {
                path: '/process/edit/:id',
                component: processedit
            },

            {
                path: '/task',
                component: task
            },
            {
                path: '/task/add',
                component: taskadd
            },
            {
                path: '/task/edit/:id',
                component: taskedit
            },
            {
                path: '/task/handletask/:id',
                component: taskhandltask
            },
            {
                path: '/tasksales',
                component: tasksales
            },
            {
                path: '/tasksales/add',
                component:tasksalesadd
            },
            {
                path: '/tasksales/edit/:id',
                component: tasksalesedit
            },
            {
                path: '/tasknotification',
                component: tasknotification
            },
            {
                path: '/tasknotification/add/:id',
                component:tasknotificationadd
            },
            {
                path: '/tasknotification/edit/:id',
                component: tasknotificationedit
            },

            {
                path: '/chaiyan',
                component: chaiyan
            },
            {
                path: '/chaiyan/add/:id',
                component:chaiyanadd
            },
            {
                path: '/chaiyan/edit/:id',
                component: chaiyanedit
            },

            {
                path: '/taskxiaoshou',
                component: taskxiaoshou
            },
           
            {
                path: '/taskxiaoshou/edit/:id',
                component: taskxiaoshouedit
            },
            
            {
                path: '/taskwanjie',
                component: taskwanjie
            },
            {
                path: '/taskwanjie/edit/:id',
                component: taskwanjieedit
            },

            {
                path: '/taskpaichan',
                component: taskpaichan
            },
            {
                path: '/taskpaichan/add/:id',
                component:taskpaichanadd
            },
            {
                path: '/taskpaichan/edit/:id',
                component: taskpaichanedit
            },
            
            {
                path: '/taskshengchan',
                component: taskshengchan
            },
            {
                path: '/taskshengchan/add/:id',
                component:taskshengchanadd
            },
            {
                path: '/taskshengchan/edit/:id',
                component: taskshengchanedit
            },
            
            {
                path: '/taskruku',
                component: taskruku
            },
            {
                path: '/taskruku/add/:id',
                component: taskrukuadd
            },
            {
                path: '/taskruku/edit/:id',
                component: taskrukuedit
            },

            {
                path: '/wkprocessaction/verify/:id',
                component: wkprocessactionverify
            },

            {
                path: '/wkprocessaction',
                component: wkprocessaction
            },
        ]
    },


]
//  创建 router 实例，然后传 `routes` 配置
const router = new VueRouter({
    routes,// (缩写) 相当于 routes: routes,
    mode: 'hash'
})
export default router