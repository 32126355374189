import request from "@/utils/request";
export function login(data){
    return request({
        url:'login',
        method:'post',
        data
    })
}
export function mpCode(params,method){
    return request({
        url:"Manager/mpCode",
        method: method,
        data:params,
        params:params
    })
}
export function editManager(params,method){
    return request({
        url:"Manager/edit",
        method: method,
        data:params,
        params:params
    })
}
export function deletetUser(data){
    return request({
        url: 'User/delete',
        method: 'post',
        data
      })
}
export function getAllManager(data){
    return request({
        url:"Manager/list",
        params:data
    })
}

export function getAuth(data){
    return request({
        url:"Auth/auth",
        data
    })
}
export function logout(token){
    return request({
        url:"manager/logout",
        method:"post",
        params:{
            token:token
        }
    })
}
export function addManager(data){
    return request({
        url: 'manager/add',
        method: 'post',
        data
      })
}
export function deletetManaer(data){
    return request({
        url: 'manager/delete',
        method: 'post',
        data
      })
}