<template>
  <div class="right-content">
    <Breadcrumb addpath="/TaskNotification/add" :crumb="crumb" :showButton="false"></Breadcrumb>
    <el-form>
      <el-form-item label="关键字" prop="name">
        <el-input
          placeholder="请输入"
          clearable
          size="small"
          style="width: 240px"
          v-model="keyword"
        >
          <el-button slot="append" class="searchbtn" @click="searchput"
            >搜索</el-button
          >
        </el-input>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="550"
      v-loading="loading"
    >
    <el-table-column fixed prop="id" label="ID"> </el-table-column>
      <el-table-column fixed prop="sn" label="任务单号"> </el-table-column>
      <el-table-column prop="title" label="销售单标题"> </el-table-column>
      <el-table-column prop="client" label="委托单位"> </el-table-column>
      <el-table-column prop="product_name" label="产品"> </el-table-column>
      <el-table-column prop="add_manager" label="上传人"> </el-table-column>
      <el-table-column prop="add_time" label="上传时间"> </el-table-column>
      <el-table-column prop="status_text" label="状态"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="edit(scope.$index)"
            type="text"
            size="small"
          >
           销售单详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
<script>
import operate from "../../api/api"
export default {
  data() {
    return {
      loading: false,
      keyword: "",
      total: 0,
      tableData: [],
      crumb:['销售单','列表']
    };
  },
  methods: {
    searchput(e) {
      operate.getAllTaskxiaoshou({name:this.keyword}).then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
    },
    addwanjie(index) {
      let id = this.tableData[index].id;
      let sn=this.tableData[index].sn;
      operate.addTaskwanjie({sn:sn}).then((response) => {
        window.location.reload();
    });
    },
    edit(index) {
      let id = this.tableData[index].id;
      let path = "/taskxiaoshou/edit/" + id;
      this.$router.push(path);
    },
    getList(p) {
      operate.getAllTaskxiaoshou({page:p}).then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
    },
  },
  mounted() {
    operate.getAllTaskxiaoshou().then((response) => {
      this.tableData = response.data;
      this.total = response.count;
    });
  }
};
</script>