<template>
  <div class="right-content">
    <Breadcrumb addpath="" :crumb="crumb" :showButton="false"></Breadcrumb>

    <el-descriptions class="margin-top" title="" :column="1" border>
      <el-descriptions-item label="提交状态">
        {{ form.state_text }}
      </el-descriptions-item>
      <el-descriptions-item label="提交备注">
        {{ form.mask }}
      </el-descriptions-item>
      <el-descriptions-item label="提交附件">
        <el-image
          v-for="item in form.images"
          style="width: 100px; height: 100px"
          :src="item"
          :key="item"
          :preview-src-list="[item]"
        ></el-image>
      </el-descriptions-item>
    </el-descriptions>
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="margin-top: 20px"
      v-if="verify_status == 0"
    >
      <el-form-item label="处理意见">
        <el-input type="textarea" v-model="form.verify_mask"></el-input>
      </el-form-item>
      <el-form-item>
        <el-radio v-model="form.verify_status" label="0">待审核</el-radio>
        <el-radio v-model="form.verify_status" label="1">通过</el-radio>
        <el-radio v-model="form.verify_status" label="-1">不通过</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>

    <el-descriptions
      class="margin-top"
      title=""
      :column="1"
      border
      style="margin-top: 20px"
      v-if="verify_status != 0"
    >
      <el-descriptions-item label="审核状态" label-class-name="my-label">
        {{ form.verify_status_text }}
      </el-descriptions-item>
      <el-descriptions-item label="审核备注" label-class-name="my-label">
        {{ form.verify_mask }}
      </el-descriptions-item>
      <el-descriptions-item label="审核时间" label-class-name="my-label">
        {{ form.verify_time }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<style>
/* .el-descriptions-item__label.is-bordered-label {
    background: #fafafa;
} */
.el-descriptions-item__label.is-bordered-label.my-label{
  background:#e1f3d8;
}
</style>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      form: {
        verify_status: "",
        verify_mask: "",
        id: 0,
      },
      verify_status: "",
      crumb: ["工单审核"],
    };
  },
  mounted() {
    let param = this.$route.params;
    let that = this;
    operate.verifyWkprocessaction(param, "get").then((response) => {
      if (response.code == "200") {
        let info = response.info;
        that.form = info;
        that.verify_status=info.verify_status;
      }
    });
  },
  methods: {
    onSubmit() {
      operate.verifyWkprocessaction(this.form, "post").then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
  },
};
</script>
