<template>
  <div class="right-content">
    <Breadcrumb
      :crumb="crumb"
      addpath="/task/add"
      :showButton="true"
    ></Breadcrumb>
    <div style="width: 80%; margin: 80px auto">
      <el-steps :active="active" finish-status="success">
        <el-step
          v-for="item in form.process"
          :status="item.status"
          :key="item.id"
          :title="item.process_name"
          :description="item.mask"
        ></el-step>
      </el-steps>
      <el-button style="margin-top: 12px" @click="next">{{
        butText
      }}</el-button>
    </div>

    <el-dialog :visible.sync="dialogFormVisible">
        <el-form
          :model="workerorderForm"
          ref="dataForm"
          label-position="left"
          label-width="90px"
          style="width: 400px; margin-left:50px;"
        >
          <el-form-item label="绑定工单">
            <el-select
              v-model="workerorder_id"
              class="filter-item"
              placeholder="工单"
            >
              <el-option
                v-for="item in workerorder"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click=" bindWorkerorder() ">确定</el-button>
        </div>
      </el-dialog>

  </div>
</template>
    
    <script>
import operate from "../../api/api";
export default {
  data() {
    return {
      active: 0,
      stepCount: 1,
      form: [],
      butText: "下一步",
      crumb: ["任务工单", "处理任务工单"],
      workerorder:'',
      workerorderForm:[],
      workerorder_id:'',
      dialogFormVisible: false
    };
  },
  mounted() {
    let param = this.$route.params;
    let that = this;
    operate.editTask(param, "get").then((response) => {
      if (response.code == "200") {
        let info = response.data;
        that.form = info;
        info.process.forEach(function (item) {
          if (item.status == "success") {
            ++that.active;
          }
        });
        if (that.active == info.process.length) {
          that.butText = "完成";
        }
        that.crumb.push(info.name);
        that.stepCount = info.process.length;
      }
    });
    operate.getAllWorkerOrder().then((response) => {
      this.workerorder = response.data;
    });
  },
  methods: {
    next() {
      if(this.active==2&&!this.form.workerorder_id){
          this.dialogFormVisible=true
          return;
      }
      let processInfo = this.form.process[this.active];
      this.$prompt("请输备注", processInfo.process_name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          operate
            .udateTaskAttr({
              task_id: processInfo.task_id,
              task_process_id: processInfo.task_process_id,
              mask: value,
              status: "success",
            })
            .then((res) => {
              if (this.active >= this.stepCount) {
                this.butText = "完成";
                this.active = this.stepCount;
              }
              this.form.process[this.active].status = "success";
              this.active++;
              window.location.reload();
            });
        })
        .catch((e) => {
          
        });
    },
    bindWorkerorder() {
        let worderOrder_id=this.workerorder_id
        let task_id=this.form.id
        operate.editTask({
          id:task_id,
          workerorder_id:worderOrder_id
        }, "post").then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
      },
  },
};
</script>