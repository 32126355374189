let dataB = {
    name: '',
    nodeList: [
    ],
    lineList: [
    ]
}

export function getDataB () {
    return dataB
}
