<template>
  <div class="right-content">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="上传头像">
        <el-upload
          class="avatar-uploader"
          action="/api/Upload/uploadImage"
          :show-file-list="false"
          :on-remove="uploadRemove"
          :on-success="uploadCallBack"
        >
          <img v-if="ruleForm.face" :src="ruleForm.face" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="昵称" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="身份">
        <el-cascader
          v-model="ruleForm.role_id"
          :options="roles"
          :show-all-levels="false"
          :props="{ checkStrictly: true }"
          @change="roleChange"
          clearable
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="小组负责人">
        <el-select v-model="ruleForm.is_leader" placeholder="请选择">
          <el-option
            v-for="item in leaderOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责车间">
        <el-select v-model="ruleForm.workshop_id" placeholder="请选择">
          <el-option
            v-for="item in workshopOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="权限">
        <el-tree
          :data="auth"
          label="label"
          show-checkbox
          node-key="name"
          ref="auth"
          @check-change="handleNodeClick"
          :default-checked-keys="defaultCheckedKeys"
          :props="defaultProps"
          :default-expand-all="true"
        >
        </el-tree>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">更新</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import { getAuth, editManager } from "../../api/user";
import operate from "../../api/api.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
        checkPass: "",
        role_id: "",
        workshop_id: "",
        auth: "",
        id: 0,
        pnode:[],
        face: "",
        is_leader:""
      },
      defaultCheckedKeys: [],
      roles: [],
      rules: {
        name: [
          { required: true, message: "昵称", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      workshopOption: [],
      leaderOption: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      auth: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      _face: "",
    };
  },
  methods: {
    handleNodeClick(node) {
      var parentIds = this.$refs.auth.getHalfCheckedKeys();
      var childsIds = this.$refs.auth.getCheckedKeys();
      console.log(parentIds);
      console.log(childsIds);
      this.ruleForm.auth = parentIds.concat(childsIds);
    },
    roleChange(value) {
      this.ruleForm.role_id = value[value.length - 1];
    },
    uploadCallBack(response, file, fileList) {
      if (response.code == 200) {
        let _obj = {
          name: response.path,
          path: response.path,
          url: response.path,
        };
        this._face = response._path;
        this.ruleForm.face = response.path;
      }
    },
    uploadRemove(file, fileList) {
      this.ruleForm.face = "";
    },
    submitForm() {
      let _data = {
        id: this.ruleForm.id,
        name: this.ruleForm.name,
        role_id: this.ruleForm.role_id,
        workshop_id: this.ruleForm.workshop_id,
        auth: this.ruleForm.auth,
        face: this._face,
        is_leader:this.ruleForm.is_leader
      };
      if (this.ruleForm.password && this.ruleForm.checkPass) {
        _data.password = this.ruleForm.password;
        _data.repass = this.ruleForm.checkPass;
      }
      editManager(_data, "post").then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              window.location.reload();
            },
          });
        }
      });
    },
    getCheckedAuth() {
      const _auth = this.$refs.auth.getCheckedKeys();
      if (_auth.length > 0) {
        this.ruleForm.auth = _auth;
      }
    },
  },
  mounted() {
    let param = this.$route.params;
    let id = param["id"];
    editManager({ id: id }, "get").then((response) => {
      let that=this
      that.ruleForm = response.data;
      that.ruleForm.workshop_id = that.ruleForm.workshop_id
        ? that.ruleForm.workshop_id
        : "";
      if (that.ruleForm.auth) {
        let _auth_arr = that.ruleForm.auth.split(",");
        let _auth=Array();
      
        _auth_arr.forEach(function(item){
         
          if(that.ruleForm.pnode.indexOf(item)>=0){
           
          }else{
            _auth.push(item);
          }
        });
        console.log(_auth)
        that.defaultCheckedKeys = _auth;
      }
      //获取角色树
      operate.getRoleTree({ roleLevel: 3 }).then((response) => {
        let _role = [{ value: 0, label: "管理员" }];
        this.roles = _role.concat(response.data);
      });
      //获取工作车间
      operate.getAllWorkshop({ is_all: true }).then((res) => {
        let _workshop = [];
        this.workshopOption = _workshop.concat(res.data);
      });
    });
    //获取权限
    getAuth().then((response) => {
      this.auth = response.data;
    });
  },
};
</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader-icon {
  line-height: 178px !important;
}
</style>