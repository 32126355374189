<template>
  <div class="right-content">
    <Breadcrumb addpath="/role/add" :crumb="crumb" :showButton="false"></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="上级角色">
        <el-cascader
            v-model="form.pid"
            :options="allRoleTree"
            :props="{ checkStrictly: true }"
            :show-all-levels="false"
            @change="roleChange"
            clearable>
      </el-cascader>
      </el-form-item>
      <el-form-item v-if="userInfo.auth.includes('addRole')">
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
  import operate from '../../api/api.js'
export default {
  data() {
    return {
      userInfo:{},
      form: {
        name: "",
        unit:'',
        pid:'',
        id:0
      },
      allRoleTree:[],
      crumb:['工作组','编辑']
    };
  },
  mounted(){
    let param=this.$route.params
    let that=this
    let userInfo=localStorage.getItem('userInfo')
    this.userInfo=JSON.parse(userInfo)
    operate.editRole(param,'get').then((response)=>{
        if(response.code=='200'){
          let info=response.data
          console.log(info)
          that.form=info
        }
     })
     operate.getRoleTree({}).then((response)=>{
        if(response.code=='200'){
         that.allRoleTree=response.data
        }
     })
  },
  methods: {
    onSubmit() {
      operate.editRole(this.form,'post').then((response)=>{
        if(response.code=='200'){
            this.$message({
            message: response.msg,
            type: 'success',
            onClose:function(){
                window.location.reload()
            }
        })
        }
     })
    },
  },
};
</script>