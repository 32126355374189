<template>
  <div class="right-content">
    <Breadcrumb
      addpath="/workerorder/add"
      :crumb="crumb"
      :showButton="false"
    ></Breadcrumb>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="标题">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="产品">
        <el-select v-model="form.product_id" placeholder="产品">
          <el-option
            v-for="item in allProduct"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工单模板">
        <el-select v-model="form.template_id" placeholder="工单模板">
          <el-option
            v-for="item in allTemplate"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预估时间">
        <el-date-picker
          v-model="form.eta_time"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
import operate from "../../api/api.js";
export default {
  data() {
    return {
      form: {
        name: "",
        template_id: "",
        eta_time: "",
        product_id: "",
      },
      allTemplate: [],
      allProduct: [],
      crumb: ["工单", "添加"],
    };
  },
  methods: {
    onSubmit() {
      let that = this;
      if (!this.form.template_id || this.form.template_id.length <= 0) {
        this.$message.error("请绑定工单模板");
        return;
      }
      if (!this.form.eta_time || this.form.eta_time.length <= 0) {
        this.$message.error("请填写预估时间");
        return;
      }
      if (!this.form.product_id || this.form.product_id.length <= 0) {
        this.$message.error("请选择产品");
        return;
      }
      if (!this.form.name || this.form.name.length <= 0) {
        this.$message.error("请输入标题");
        return;
      }
      operate.addWorkerOrder(this.form).then((response) => {
        if (response.code == "200") {
          this.$message({
            message: response.msg,
            type: "success",
            onClose: function () {
              that.$router.back();
            },
          });
        }
      });
    },
  },
  mounted() {
    operate.getAllTemplate().then((response) => {
      this.allTemplate = response.data;

      operate.getAllProduct({ is_all: true }).then((response) => {
        this.allProduct = response.data;
      });
    });
  },
};
</script>